import appConfig from '../config/main.config'
const apiserv = appConfig.url_api;

export const searchCoupon = async (coupon) => {

    const apicoupon = `${apiserv}/promotions/${coupon}`;
    const response = await fetch(apicoupon);
    if (response.ok) {
        const body = await response.json();
        return body;
    } else {
        const res = { valid: false }
        return res;
    }

}
export const calculateDiscount = (coupon, idProd, priceProd, cycleProd) => {
    const productsValid = coupon.appliesto.split(',');
    let discount = 0;

    const isProduct = productsValid.find(element => element === idProd);

    const cycles = coupon.cycles.toLowerCase();
    const cyclesarray = cycles.split(',');
    const isCycle = cyclesarray.find(el => el === cycleProd)

    if (isProduct && isCycle) {
        //console.log('product y ciclo si es igual')

        if (coupon.type === 'Percentage') {
            discount = (priceProd * (coupon.value / 100)).toFixed(2);
        }
        if (coupon.type === 'Fixed Amount') {
            discount = (coupon.value).toFixed(2);
        }

        //console.log('discount', discount)
    }

    return discount;
}