import React, {useRef, useState} from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import { authUser } from '../services/User';
import { saveAuth } from '../hooks/useAuth';
import appConfig from '../config/main.config';

const Login = () => {
  const navigate = useNavigate();
  const captcha = useRef(null);

  //const [validUser, setValidUser] = useState(false)
  const [validCaptcha, setValidCaptcha] = useState(null)
  const [errValidUser, setErrValidUser] = useState(null)

  const onChangeCaptcha = () =>{
    if(captcha.current.getValue()){
      //console.log('no es robot')
      setValidCaptcha(true)
    }else{
      setValidCaptcha(false)
    }
  }


  return (
    <>
      <Helmet>
        <title>Neothek | Entrar</title>
      </Helmet>
      <Box
        sx={{
          /*backgroundColor: 'background.default',*/
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm" variant='cont-nk'>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('E-mail inválido').max(255).required('Email es requerido'),
              password: Yup.string().max(255).required('Contraseña es requerida')
            })}

            onSubmit={async (val, actions) => {
  
              const res = await authUser(val.email, val.password);

                if(res.status === 1){
                    //navigate('/app/dashboard', { verify: true });
                    setErrValidUser(null);
                    //setValidUser(true)
                    saveAuth(res);

                    if(captcha.current.getValue() !== ''){
                      //console.log('ambas verificaciones')
                      navigate('/clientarea/dashboard/');
                    }else{
                      console.log('entra a si no')
                      /*if(validCaptcha != true){
                        setValidCaptcha(false)
                      }*/
                    } 

                }else{
                    //console.log(res.mensaje)
                    actions.setSubmitting(false)
                    setErrValidUser(res.message)

                    actions.resetForm()
                    
                }

      
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 2, mt: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    align="center"
                  >
                    Login
                  </Typography>
                  
                </Box>

                <Box sx={{pt: 1, pb: 2}}>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body1"
                        align="center"
                    >
                        Ingresar al Área de Clientes
                    </Typography>
                </Box>

              {errValidUser &&
                <Box>
                  <Typography sx={{color: 'red'}} variant="h6">
                    {errValidUser}
                  </Typography>
                </Box>
              }
                
               
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Correo electrónico"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  sx={{marginBottom: '16px'}}
                />

                <Typography
                  color="textSecondary"
                  variant="body1"
                  align='right'
                  sx={{fontWeight: '600'}}
                >
                  <Link
                    component={RouterLink}
                    to="/reset/"
                    variant="h6"
                  >
                    ¿No recuerdas la contraseña?
                  </Link>
                </Typography>

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Introduce tu contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  sx={{marginTop: '8px'}}
                />

                <Box align='center' sx={{py: 2}}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey={appConfig.sitekey_captcha}
                    onChange={onChangeCaptcha}
                  />
                 {validCaptcha === false && 
                    <Typography sx={{color: 'red'}} variant="h6">
                      Por favor acepte el capctha
                    </Typography>
                 }
                  
                </Box>


                <Box sx={{ py: 3 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Ingresar
                  </Button>
                </Box>
                <Box sx={{paddingBottom: 3}}>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  ¿No tienes una cuenta?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Registrarse
                  </Link>
                </Typography>
                </Box>
                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
