import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function getInvoicesClient() {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            return res.data;
            //return  {...res.data, ...{error: false}};
        })
        .catch((error) => {
            //console.log('error', error.config);
            //return  {error: true};
            return [];

        });

    return client;

}