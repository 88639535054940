import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import appConfig from '../../config/main.config';
import { Box, Button } from '@material-ui/core';
import iniSessionStripe from '../../services/PaymentStripeSession';

//const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
//const stripePromise = loadStripe('pk_test_Fktrr3cFoE6jt7Vn9C89l8ac'); Ofi
//const stripePromise = loadStripe('pk_test_51J33QqHR8NMwG6qnwfqjvrKUfnNTMAF3qCOdLu1PD4wbnuefU0CrEy8cubcLdvwfMTd0jj8Tq9DVTygzOeiyRIOm00Lj6HAG73');
const stripePromise = loadStripe(appConfig.pk_stripe);

const StripeButton = (props) => {
  const handleClick = async (event) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    //const response = await fetch('/gateways/stripe/create-checkout-session', { method: 'POST' });
    const session = await iniSessionStripe(props.invoice);
    //console.log('response stripe', session);
    //const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    } else {
      console.log(result)
    }
  };


  return (
    <Box sx={{px:2}}>
    <Button variant="contained" style={{color: '#fff'}} fullWidth onClick={handleClick}>
        Pago Único
    </Button>
    </Box>
    
    /* <button role="link" onClick={handleClick}>
      Checkout Stripe
    </button> */
    
  )
}

export default StripeButton;