//import neothekBlancoMobile from '../../public/static/neonavbar/img/neothek-blanco-mobile.svg';
//import $ from 'jquery';
import { useState } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

const NeoNavbar = (props) => {

    const [isOpenH, setIsOpenH] = useState(false);
    const [isOpenE, setIsOpenE] = useState(false);
    const [isOpenDo, setIsOpenDo] = useState(false);
    const [isOpenS, setIsOpenS] = useState(false);
    const [isOpenDi, setIsOpenDi] = useState(false);

    const [isOpenMob, setIsOpenMob] = useState(false)
    

    const toggleOpenH = () => {
        setIsOpenH(!isOpenH); 
        if(!isOpenH){
            setIsOpenE(false)
            setIsOpenDo(false)
            setIsOpenS(false)
            setIsOpenDi(false)
        }
    };
    const toggleOpenE = () => {
        setIsOpenE(!isOpenE)
        if(!isOpenE){
            setIsOpenH(false)
            setIsOpenDo(false)
            setIsOpenS(false)
            setIsOpenDi(false)
        }
    }
    const toggleOpenDo = () => {
        setIsOpenDo(!isOpenDo)
        if(!isOpenDo){
            setIsOpenH(false)
            setIsOpenE(false)
            setIsOpenS(false)
            setIsOpenDi(false)
        }
    }
    const toggleOpenS = () => {
        setIsOpenS(!isOpenS)
        if(!isOpenS){
            setIsOpenH(false)
            setIsOpenE(false)
            setIsOpenDo(false)
            setIsOpenDi(false)
        }
    }
    const toggleOpenDi = () => {
        setIsOpenDi(!isOpenDi)
        if(!isOpenDi){
            setIsOpenH(false)
            setIsOpenE(false)
            setIsOpenDo(false)
            setIsOpenS(false)
        }
    }

    const toggleMob = () => {
        setIsOpenMob(!isOpenMob)
    }


    return(
        <>
        <AppBar sx={{ backgroundColor: 'white', top: '-1px', boxShadow: '0 0 1px 0 rgb(0 0 0 / 0%), 0 3px 4px -2px rgb(0 0 0 / 10%)'}}> 
        <Toolbar>
        
        {<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"/>}
        {<link rel="stylesheet" href={`${process.env.PUBLIC_URL}/static/neonavbar/css/bootstrap.min.css`} type="text/css"/>}
        {<link rel="stylesheet" href={`${process.env.PUBLIC_URL}/static/neonavbar/css/font-awesome.min.css`} type="text/css"/>}
        {<link rel="stylesheet" href={`${process.env.PUBLIC_URL}/static/neonavbar/css/menu2021/styles-neothek-header.css`} type="text/css"/>}
        
        {<link rel="stylesheet" href={`${process.env.PUBLIC_URL}/static/neofooter/css/styles-neothek-footer.css`} type="text/css"/>}

        
        {/*<script src={`${process.env.PUBLIC_URL}/static/neonavbar/js/jquery/1.12.4/jquery.min.js`} type="text/javascript"/>*/}
        {<script src={`${process.env.PUBLIC_URL}/static/neonavbar/js/bootstrap/bootstrap.min.js`} type="text/javascript"/>}
        
    
    <div className="container text-center">

    <nav className="navbar  navbar-mobile navbar-neothek navbar-fixed-top visible-xs-inline-block" >
    <div className="container">
        <div className="navbar-header navbar-header-mobile">

        <button type="button" className={`navbar-toggle nav-neo-mobile nav-mobile ${isOpenMob?'change':'collapsed'}`} data-toggle="collapse" data-target="#myNavbar" id="btnMob" onClick={toggleMob}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </button>

        
        <a className="navbar-brand navbar-brand-left" href="/"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/neothek-blanco-mobile.svg`}  alt="Neothek Web Hosting"/></a>
        
        </div>
        <div className={`navbar-collapse navbar-collapse-mobile text-center ${isOpenMob?'collapse in':'collapse'}`} id="myNavbar">

        <ul className="nav navbar-nav navbar-nav-mobile">

            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-hosting"><a href="https://www.neothek.com/web-hosting/" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Hosting <i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>

            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
    
            <li className="col-sm-15">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/web-hosting/"> Web Hosting</a> </li>

                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/web-hosting/hosting-ilimitado/"> Hosting Ilimitado</a> </li>
                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/reseller-web-hosting/"> Reseller Hosting</a> </li>
                </ul>
            </li>
            
            <li className="col-sm-15">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/e-comercio-electronico/"> E-commerce </a> </li>
                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/servidores-vps/"> Servidores VPS </a> </li>
                </ul>
            </li>
            </ul>
            
        </li>


            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-correo" >
            <a href="https://www.neothek.com/correo-electronico/" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">E-mail<i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>
            
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
            <li className="col-sm-12">
                <ul>     
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/"> Correo Electrónico</a> </li>
                </ul>

            </li>

            <li className="col-sm-4">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/email-hosting/"> E-mail Hosting</a> </li>
                </ul>
            </li>
            <li className="col-sm-4">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/google-gsuite/"> Google Suite</a> </li>
                </ul>
            </li>
            <li className="col-sm-4">
                <ul>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/crear-cuentas-email/">Cuentas Email</a> </li>
                </ul>
            </li>
            
            </ul>
    
            </li>



            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-dominios">
            <a href="https://www.neothek.com/dominios/" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Dominios<i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/dominios/">Registro de Dominio</a> </li>
                    </ul>
                </li>
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="http://neothek.net/login.php">Panel de Control</a> </li>
                    </ul>
                </li>
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="http://www.neothek.net/domain.php?action=whois">WHOIS</a> </li>
                    </ul>
                </li>
            
            </ul>
            </li>



            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-seguridad" >
            <a href="https://www.neothek.com/certificados-ssl/" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Seguridad<i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/certificados-ssl/">Certificados SSL</a> </li>
                    </ul>
                </li>
                <li className="col-sm-2">
                    <ul>        
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/firmas-digitales/">Firmas Digitales</a> </li>
                    </ul>
                </li>
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/antivirus/">Antivirus</a> </li>
                    </ul>
                </li> 
                <li className="col-sm-2">
                    <ul>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/seguridad/vpn/">VPN</a> </li>
                    </ul>
                </li>
            
            </ul>
            
            </li>

            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-diseno">
            <a href="https://www.neothek.com/diseno-web/" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Diseño <i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
            <li className="col-sm-2">
                <ul>           
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-web/"> Diseño Web</a> </li>
                </ul>
            </li>
            <li className="col-sm-2">
                <ul>              
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-grafico/"> Diseño Gráfico</a> </li>
                </ul>
            </li>

        
            </ul>
        
            </li>
            
            
            <li className="dropdown dropdown-large dropdown-neothek dropdown-large-soporte">
                    <a href="https://clientes.neothek.com/clientarea.php" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">Soporte <i className="glyphicon glyphicon-menu-down icono-down icono-mobile"></i> <i className="glyphicon glyphicon-menu-up icono-down icono-mobile"></i></a>
                    <ul className="dropdown-menu dropdown-menu-large dropdown-menu-mobile row">
                    <li className="col-sm-2">
                        <ul>
                        <li style={{textAlign: 'center'}}> <a href="https://clientes.neothek.com/clientarea.php"> Área Clientes</a> </li>
                        </ul>
                    </li>
                    <li className="col-sm-2">
                        <ul>
                        <li style={{textAlign: 'center'}}> <a href="https://clientes.neothek.com/submitticket.php?step=2&deptid=1"> Generar Ticket</a> </li>
                        </ul>
                    </li>
                    </ul>
            </li>


            <li className="dropdown dropdown-large dropdown-large-contact">
            <div>
                <a href="https://api.whatsapp.com/send?phone=18722444628" className="btn btn-contact-mobile btn-group-justified" role="button" style={{padding: '7px 8px'}}><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/whatsapp-logo.svg`} alt="Botón para Whatsapp" /></a>
            </div>

            <div className="col-lg-4 col-md-4 text-left" style={{paddingTop: '3%', paddingBottom: '5%'}}>
                <div className="btn-group">

                <a href="http://www.facebook.com/pages/Neothek/89057328264" className="" style={{textDecoration: 'none'}}>  <span className="fa fa-facebook-f icon-contact-fb icon-fb"> </span>  </a>

                <a href="http://twitter.com/neothek/" className="" style={{textDecoration: 'none'}}>  <span className="fa fa-twitter icon-contact-foot icon-tw"> </span>  </a>

                <a href="http://www.youtube.com/user/NeothekHosting" className="" style={{textDecoration: 'none'}}>  <span className="fa fa-youtube icon-contact-foot icon-ytb"> </span>  </a>
                
                <a href="https://www.neothek.com/contacto/" className="" style={{textDecoration: 'none', padding: '0 8px'}}>  <span className="fa fa-envelope icon-contact-foot icon-blog"> </span>  </a>

                </div>

            </div> 
            
            <div className="col-xs-12 foot-menu-mobile"></div>
            
            </li>

            

        </ul>
        
        
        </div>  



    </div>


    </nav>


    <nav className="navbar  navbar-neothek navbar-neothek1 navbar-fixed-top hidden-xs" data-spy="affix" >
        
    <div className="container">
        <div className="navbar-header">
        <button type="button" className="navbar-toggle nav-mobile" data-toggle="collapse" data-target="#myNavbarB">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>                        
        </button>
        <button type="button" className="navbar-toggle pull-right btn-contact" data-toggle="collapse" data-target=".navbar-ex2-collapse" style={{padding: '7px 10px 4px'}}> <span className="sr-only">Toggle navigation</span>
                <i className="glyphicon glyphicon-envelope" style={{fontSize: '16px', color: '#fff'}}></i>
        </button>
        <a className="navbar-brand navbar-brand-left" href="/"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/neothek-color.png`} alt="Neothek Web Hosting" id="logo"/></a>
        
        </div>


        <div className="navbar-header pull-right">
            
            <a href="https://www.neothek.com/contacto/" className="btn btn-default navbar-btn pull-right btn-contact2 fd-princ-affix" >
                <span className="hidden-md hidden-sm"> Contáctenos </span> <span className="fa fa-phone visible-md-inline-block visible-sm-inline-block" style={{fontSize: '19px'}}></span>
            </a>
        </div>



        <div className="collapse navbar-collapse text-center" id="myNavbarB">

        <ul className="nav navbar-nav">

            
        <li className={`dropdown dropdown-large dropdown-neothek ${isOpenH?'open':''}`} onClick={toggleOpenH}><a href="#" className="dropdown-toggle" data-toggle="dropdown"  >Hosting <i className="glyphicon glyphicon-menu-down icono-down"></i></a>

            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-host row" style={{width: '712px'}}>
            <li className="col-sm-15">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/web-hosting/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/web-hosting-ico.png`}  alt="Neothek Web Hosting"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/web-hosting/"> Web Hosting</a> </li>

                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/web-hosting/hosting-ilimitado/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img//iconos/host-ilimitado-ico.png`}  alt="Neothek Hosting Ilimitado"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/web-hosting/hosting-ilimitado/"> Hosting Ilimitado</a> </li>
                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/reseller-web-hosting/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/reseller-ico.png`}  alt="Neothek Reseller Hosting"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/reseller-web-hosting/"> Reseller Hosting</a> </li>
                </ul>
            </li>
            
            <li className="col-sm-15">
                <ul>
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/e-comercio-electronico/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/e-comerce-ico.png`}  alt="Neothek E-commerce"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/e-comercio-electronico/"> E-commerce </a> </li>
                </ul>
            </li>
            <li className="col-sm-15">
                <ul>
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/servidores-vps/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/vps-ico.png`}  alt="Neothek Servidores VPS"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/servidores-vps/"> Servidores VPS </a> </li>
                </ul>
            </li>
            </ul>
            
        </li>

        
        <li className={`dropdown dropdown-large dropdown-neothek ${isOpenE?'open':''}`} onClick={toggleOpenE}>
            <a href="#" className="dropdown-toggle" data-toggle="dropdown"><span className="visible-md-inline-block visible-sm-inline-block">E-mail</span><span className="hidden-md hidden-sm">Correo Electrónico</span><i className="glyphicon glyphicon-menu-down icono-down"></i></a>
            
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-cor row">
                
            <li className="col-sm-12" style={{paddingBottom: '1%'}}>
                <a href="https://www.neothek.com/correo-electronico/" className="text-center lnk-cor">Correo Electrónico</a>
            </li> 
                
            <li className="col-sm-4">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/correo-electronico/email-hosting/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/e-mail-profesional-ico.png`}  alt="Neothek E-mail Hosting"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/email-hosting/">E-mail Hosting</a> </li>

                </ul>
            </li>
            <li className="col-sm-4">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/correo-electronico/google-gsuite/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/gsuite-ico.png`}  alt="Neothek Google Suite"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/google-gsuite/"> Google Suite</a> </li>
                </ul>
            </li>
            <li className="col-sm-4">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/correo-electronico/crear-cuentas-email/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/email-new-ico.png`}  alt="Neothek Email"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/correo-electronico/crear-cuentas-email/" style={{paddingLeft: '30%', paddingRight: '30%'}}> Email</a> </li>
                </ul>
            </li>
            
            
            </ul>
            
            </li>



            <li className={`dropdown dropdown-large dropdown-neothek ${isOpenDo?'open':''}`} onClick={toggleOpenDo}>
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" >Dominios<i className="glyphicon glyphicon-menu-down icono-down"></i></a>
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-dom row">
                <li className="col-sm-4">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/dominios/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/reg-dominio-ico.png`}  alt="Neothek Registro de Dominio"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/dominios/">Registro de Dominio</a> </li>

                    </ul>
                </li>
                <li className="col-sm-4">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="http://neothek.net/login.php" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/panel-control-ico.png`}  alt="Neothek Panel de Control"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="http://neothek.net/login.php">Panel de Control</a> </li>
                    </ul>
                </li>
                <li className="col-sm-4">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="http://www.neothek.net/domain.php?action=whois" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/whois-ico.png`}  alt="Neothek WHOIS"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="http://www.neothek.net/domain.php?action=whois">WHOIS</a> </li>
                    </ul>
                </li>
            
            </ul>
            </li>



            <li className={`dropdown dropdown-large dropdown-neothek ${isOpenS?'open':''}`} onClick={toggleOpenS} >
            <a href="#" className="dropdown-toggle" data-toggle="dropdown">Seguridad<i className="glyphicon glyphicon-menu-down icono-down"></i></a>
            <ul className="dropdown-menu dropdown-menu-large dropdown-menu-seg row">
                <li className="col-sm-3">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/certificados-ssl/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/ssl-ico.png`}  alt="Neothek Certificados SSL"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/certificados-ssl/">Certificados SSL</a> </li>

                    </ul>
                </li>
                <li className="col-sm-3">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/firmas-digitales/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/firmas-dig-ico.png`}  alt="Neothek Firmas Digitales"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/firmas-digitales/">Firmas Digitales</a> </li>
                    </ul>
                </li> 
                <li className="col-sm-3">
                    <ul>
                    
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/antivirus/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/antivirus-ico.png`}  alt="Antivirus"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/antivirus/">Antivirus</a> </li>
                    </ul>
                </li>
                <li className="col-sm-3">
                    <ul>
                    <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/seguridad/vpn/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/vpn-ico.png`}  alt="Red Privada Virtual"/></a></li>
                    <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/seguridad/vpn/">VPN</a> </li>
                    </ul>
                </li> 
            
            </ul>
            
            </li>
            
            
            
            <li className={`dropdown dropdown-large dropdown-neothek ${isOpenDi?'open':''}`} onClick={toggleOpenDi}>
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" >Diseño <i className="glyphicon glyphicon-menu-down icono-down"></i></a>
            <ul className="dropdown-menu dropdown-menu-large  dropdown-menu-dis row">
            <li className="col-sm-3">
                <ul>
                

                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/diseno-web/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/dis-web-ico.png`}  alt="Neothek Diseño Web"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-web/"> Diseño Web</a> </li>

                </ul>
            </li>
            <li className="col-sm-3">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/diseno-grafico/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/dis-graf-ico.png`}  alt="Neothek Diseño Gráfico"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-grafico/"> Diseño Gráfico</a> </li>
                </ul>
            </li>
            <li className="col-sm-3">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/diseno-web/crear-pagina-web/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/constructor-sitios-ico.png`}  alt="Neothek Constructor Sitios"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-web/crear-pagina-web/"> Constructor Sitios</a> </li>
                </ul>
            </li>
            <li className="col-sm-3">
                <ul>
                
                <li className="dropdown-header" style={{textAlign: 'center'}} ><a href="https://www.neothek.com/diseno-web/moviles/" className="esp-icon-head"><img src={`${process.env.PUBLIC_URL}/static/neonavbar/img/iconos/dis-movil-ico.png`}  alt="Neothek Diseño Móvil"/></a></li>
                <li style={{textAlign: 'center'}}> <a href="https://www.neothek.com/diseno-web/moviles/"> Diseño Móvil </a> </li>
                </ul>
            </li>
            
            </ul>
        
            </li>

           {/* */}
        </ul>

        
    
        </div>
    
        
    </div>
    </nav> 


    </div>

        </Toolbar>
        </AppBar>  
        </>
        )
    }
    
    export default NeoNavbar