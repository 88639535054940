import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { logout } from '../hooks/useAuth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  [theme.breakpoints.down('sm')]: {
    contMainNav: {
      marginTop: '14%'
    }
  },
  [theme.breakpoints.up('sm')]: {
    contMainNav: {
      marginTop: '8%'
    }
  },
  [theme.breakpoints.up('md')]: {
    contMainNav: {
      marginTop: '5.8%'
    }
  },
  [theme.breakpoints.up('lg')]: {
    contMainNav: {
      marginTop: '4.5%',
      display: 'none'
    }
  },
  [theme.breakpoints.up('xl')]: {
    contMainNav: {
      marginTop: '3.4%',
      display: 'none'
    }
  }
  
}));

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const navigate = useNavigate();

  const handleLogout = () => {
      logout();
      navigate('/')
  }

  return (
    <AppBar className={classes.contMainNav}
      sx={{backgroundColor: '#fbfbfb'}}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          {/* <Logo /> */}
          Portal Neothek
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="primary"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
