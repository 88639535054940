import React, { useMemo } from 'react'
import {TextField, MenuItem} from '@material-ui/core'
import countryList from 'react-select-country-list'
import { Field, useField, useFormikContext } from 'formik';

function CountrySelector( {name, sendCodeCountry, ...otherProps} ) {
    const options = useMemo(() => countryList().getData(), [])
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name)

    const changeHandler = ev => {
        sendCodeCountry(ev.target.value);
        //setValue(ev.target.value);
        const { value } = ev.target;
        setFieldValue(name, value);   
      }

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        size:'small',
        variant:'outlined',
        fullWidth: true,
        onChange: changeHandler
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
      }

    return(
        <Field as={TextField} {...configSelect} >

        {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}

        </Field>
    )
  
  
}

export default CountrySelector