import React, { useState, useEffect } from 'react'
import { Container, Typography } from '@material-ui/core';
import axios from 'axios';
import verifyKey2CO from '../../services/Payment2coVerified';
import setPaydout2CO from '../../services/Payment2co';
import { useNavigate, useLocation } from 'react-router-dom';
//http://localhost:3000/clientarea/return-2co?uid=7854&invoiceid=7854&key=785323&total=45.85&order_number=222

function Return2CO(props) {
    const [dataVerified, setDataVerified] = useState(false);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(useLocation().search);

    //console.log('url params search', urlParams)
    const orderKey = urlParams.get('key');
    const orderTotal = urlParams.get('total');
    const orderNumber = urlParams.get('order_number');


    useEffect(() => {
        if (orderKey && orderTotal && orderNumber) {
            const data = {
                key: orderKey,
                order_number: orderNumber,
                total: orderTotal
            };

            //console.log('data 2Checkout', data);

            (async function () {
                //const dataverified = await verifyKey2CO(data);
                const dataverified = true;
                console.log('dataverified 2co', dataverified)

                if (dataverified) {
                    setDataVerified(dataverified);

                    const invoicedetail = {
                        userid: urlParams.get('uid'),
                        currency: 0,
                        gateway: 'tco',
                        description: 'Invoice Payment',
                        invoiceid: urlParams.get('invoiceid')
                    }

                    const paymentdetail = {
                        order_number: urlParams.get('order_number'),
                        pay_method: urlParams.get('pay_method'),
                        sid: urlParams.get('sid'),
                        invoice_id: urlParams.get('invoice_id'),
                        li_0_price: urlParams.get('li_0_price'),
                        li_0_quantity: urlParams.get('li_0_quantity'),
                        total: urlParams.get('total'),
                        first_name: urlParams.get('first_name'),
                        last_name: urlParams.get('last_name'),
                        card_holder_name: urlParams.get('card_holder_name'),
                        email: urlParams.get('email'),
                        phone: urlParams.get('phone'),
                        country: urlParams.get('country'),
                        ip_country: urlParams.get('ip_country'),
                        currency_code: urlParams.get('currency_code'),
                        city: urlParams.get('city'),
                        zip: urlParams.get('zip'),
                        state: urlParams.get('state'),
                        street_address: urlParams.get('street_address'),
                        street_address2: urlParams.get('street_address2'),
                        li_0_description: urlParams.get('li_0_description'),
                        li_0_name: urlParams.get('li_0_name'),
                        key: urlParams.get('key'),
                        li_0_type: urlParams.get('li_0_type'),
                        lang: urlParams.get('lang'),
                        credit_card_processed: urlParams.get('credit_card_processed'),
                        cart_weight: urlParams.get('cart_weight'),
                        fixed: urlParams.get('fixed'),
                        submit: urlParams.get('submit'),
                        li_0_product_id: urlParams.get('li_0_product_id'),
                        li_0_tangible: urlParams.get('li_0_tangible'),
                        merchant_order_id: urlParams.get('merchant_order_id'),
                        cart_tangible: urlParams.get('cart_tangible'),
                        x_receipt_link_url: urlParams.get('x_receipt_link_url'),
                    };

                    const infostring = JSON.stringify(paymentdetail, null, 2);

                    const recordPayment = await setPaydout2CO(paymentdetail, invoicedetail);
                    console.log('pago 2co status', recordPayment)

                    // navigate('/clientarea/success-payment/');
                }

            }())

        }

    }, [])

    return (
        <div>

            {dataVerified ? (
                <Container>
                    <Typography variant='body2' style={{ textAlign: 'center' }}>Loading Payment...</Typography>
                </Container>
            ) : (
                <Container>

                </Container>
            )}


        </div>
    )
}

export default Return2CO;