import React from 'react';
import { Box, Chip, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@material-ui/core';

const TableDomains = (props) => {

    const { domains, page, limit, handlePageChange, handleLimitChange } = props;

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table aria-label="domains table" sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Nombre de Dominio</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="right"><strong>Fecha de Registro</strong></TableCell>
                            <TableCell align="right"><strong>Vencimiento</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {domains.slice(page * limit, page * limit + limit).map((domain) => (
                            <TableRow hover key={domain.id}>
                                <TableCell component="th" scope="row" variant='celltbLight-nk'>
                                    {domain.name}
                                </TableCell>
                                <TableCell align="left" variant='celltbLight-nk'>
                                    {(domain.status === 'Pending' || domain.status === 'Pending Registration') &&
                                        <Chip color="secondary" size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {domain.status === 'Active' &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {domain.status === 'Pending Transfer' &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#c0ddff', color: '#6773c9' }} />
                                    }
                                    {(domain.status === 'Grace' || domain.status === 'Redemption' || domain.status === 'Expired' || domain.status === 'Cancelled' || domain.status === 'Transferred Away') &&
                                        <Chip size="small" label={domain.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                </TableCell>
                                <TableCell align="right" variant='celltbLight-nk'>{domain.registrationdate}</TableCell>

                                {domain.expirydate === '00/00/0000' ?
                                    <TableCell align="right" variant='celltbLight-nk'>{domain.registrationdate}</TableCell>
                                    :
                                    <TableCell align="right" variant='celltbLight-nk'>{domain.expirydate}</TableCell>
                                }

                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={domains.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>

    );
}

export default TableDomains;