import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const getDomainTlds = async () => {
    const response = await fetch(`${apiserv}/domains/extensions/`)
    const body = await response.json();

    return body;
}

export const getProductAvailable = async (idProd) => {
    //const apiprod = '/products/isavailable/' + idProd;
    const apiprod = `${apiserv}/products/isavailable/${idProd}`
    const response = await fetch(apiprod);
    if (response.ok) {
        const body = await response.json();
        return body;
    } else {
        const product = false;
        return product;
    }

}

export const getProduct = async (idProd) => {
    //const apipriceprod = '/products/prices/' + idProd;
    const apipriceprod = `${apiserv}/products/prices/${idProd}`;

    const response = await fetch(apipriceprod);
    const body = await response.json();

    return body;
}
//dar array de ciclo de product
export const getCycleProduct = (body) => {
    let arrayperiod = [];

    if (body.monthly !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Mensual';
        cycleprod.price = body.monthly;
        cycleprod.periodeng = 'monthly';
        arrayperiod.push(cycleprod);
    };
    if (body.quarterly !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Trimestral';
        cycleprod.price = body.quarterly;
        cycleprod.periodeng = 'quarterly';
        arrayperiod.push(cycleprod);
    };
    if (body.semiannually !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Semi-Anual';
        cycleprod.price = body.semiannually;
        cycleprod.periodeng = 'semiannually';
        arrayperiod.push(cycleprod);
    };
    if (body.annually !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Anual';
        cycleprod.price = body.annually;
        cycleprod.periodeng = 'annually';
        arrayperiod.push(cycleprod);
    };
    if (body.biennially !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Bi-Anual';
        cycleprod.price = body.biennially;
        cycleprod.periodeng = 'biennially';
        arrayperiod.push(cycleprod);
    };
    if (body.triennially !== -1) {
        let cycleprod = {};
        cycleprod.period = 'Tri-Anual';
        cycleprod.price = body.triennially;
        cycleprod.periodeng = 'triennially';
        arrayperiod.push(cycleprod);
    };

    return arrayperiod;
}

export const getAddons = async (idProd) => {
    const api = `${apiserv}/addons/products/${idProd}`;
    const res = await fetch(api);
    const apiaddons = await res.json();

    const addonsarray = [];

    apiaddons.map((apiaddon) => {
        let addn = { ischeck: false };
        addn = { ...apiaddon, ...addn };
        addonsarray.push(addn);
    });

    //return addons de product mas stauts ischeckfalse
    return addonsarray;
}

//get addons filtrando tipo de addon
export const getMinAddons = async (addons) => {
    let addonsminarr = [];

    addons.map((body) => {
        let mincyclenew = {};

        //si billyngcyle no es onetime o free
        if (body.billingcycle === "recurring") {

            if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Mensual'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
            else {
                if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Trimestral'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
                else {
                    if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Semi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                        if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Anual'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                            if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Bi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Tri-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) };
                            }
                        }
                    }
                }
            }


        } //si es onetime o free
        else {
            //si es onetime
            if (body.billingcycle === "onetime") {
                if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
                else {
                    if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
                    else {
                        if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                            if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                    if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Una vez'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) };
                                }
                            }
                        }
                    }
                } //fin

            } else {
                //si es free
                if (body.billingcycle === "free") {

                    mincyclenew.name = body.name; mincyclenew.period = 'Gratis!'; mincyclenew.description = body.description; mincyclenew.price = 0; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew)

                }//si no es onetime - free- recurring, lo mismo q recurring temporal
                else {
                    //ini
                    if (body.monthly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Mensual'; mincyclenew.description = body.description; mincyclenew.price = body.monthly; mincyclenew.periodeng = 'monthly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
                    else {
                        if (body.quarterly !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Trimestral'; mincyclenew.description = body.description; mincyclenew.price = body.quarterly; mincyclenew.periodeng = 'quarterly'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) }
                        else {
                            if (body.semiannually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Semi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.semiannually; mincyclenew.periodeng = 'semiannually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                if (body.annually !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Anual'; mincyclenew.description = body.description; mincyclenew.price = body.annually; mincyclenew.periodeng = 'annually'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                    if (body.biennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Bi-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.biennially; mincyclenew.periodeng = 'biennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) } else {
                                        if (body.triennially !== -1) { mincyclenew.name = body.name; mincyclenew.period = 'Tri-Anual'; mincyclenew.description = body.description; mincyclenew.price = body.triennially; mincyclenew.periodeng = 'triennially'; mincyclenew.id = body.id; addonsminarr.push(mincyclenew) };
                                    }
                                }
                            }
                        }
                    }//end

                }
            }

        }



    });

    //poner minimos para mostrar en list, add description, periodeng, recurring
    //console.log('min addons', addonsminarr)
    return addonsminarr;
}

export const getValIniProd = (cycleProd, cycle) => {
    let price = 0;
    let res = {};
    cycleProd.map((billingcycle) => {
        if (billingcycle.periodeng === cycle) {
            price = billingcycle.price;
        }
    });

    res = { price: price, billingcycle: cycle };

    //en caso de que cycle no exista, return el minimo cycle
    if (price === 0) {
        const pricemin = cycleProd[0].price;
        const cyclemin = cycleProd[0].periodeng;
        res = { price: pricemin, billingcycle: cyclemin };
    }

    return res;
}

export const getIsFreeDomain = (tld, billingcycle, product) => {
    const cyclesfree = product.freedomainpaymentterms.split(',');
    const tldsfree = product.freedomaintlds.split(',')
    let res = { isFree: false, type: '' };
    const tldfree = tldsfree.find(n => n === tld);
    const cyclefree = cyclesfree.find(n => n === billingcycle);

    if ((product.freedomain === 'on' || product.freedomain === 'once') && tldfree !== undefined && cyclefree !== undefined) {
        res = { isFree: true, type: product.freedomain };
    }

    return res;


}