import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'
import { logout } from '../hooks/useAuth'
import { getDataClient } from '../services/Client';
import getInvoice from '../services/Invoice';
import { Typography } from '@material-ui/core';
import SpinnerLoad from '../components/FormsUI/Spinner';

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();

    const [fullservices, setServices] = useState(null);
    const [domains, setDomains] = useState(null);

    //to pagination
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    //to filter
    const [serviceStatus, setServiceStatus] = useState('all');
    const [services, setFilteredServices] = useState(null);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };
    const handleServiceStatusChange = (event, newValue) => {
        setPage(0);
        setServiceStatus(newValue);
    };

    /*pagination and filter */

    const handleError = () => {
        logout();
        navigate('/login')
    }

    useEffect(() => {
        //controlar el error que se recibe con la API, si es expiración de API redirect logout
        async function isClient() {
            const client = await getDataClient();

            //console.log('value client', client)

            if (client) {
                setFirstname(client.firstname);
                setLastname(client.lastname);

                if (location.state !== null) {
                    const status = location.state.data.status;

                    if (status === 'buyinprogress') {

                        const dataorder = {
                            clientid: client.client_id,
                            country: client.country,
                            email: client.email,
                            addons: location.state.data.addons,
                            billingcycle: location.state.data.billingcycle,
                            domain: location.state.data.domain,
                            pricedomain: location.state.data.pricedomain,
                            pricerenewdomain: location.state.data.pricerenewdomain,
                            eventdomain: location.state.data.eventdomain,
                            idproduct: location.state.data.idproduct,
                            nameproduct: location.state.data.nameproduct,
                            paymentmethod: client.pgdefault.gateway,
                            priceproduct: location.state.data.priceproduct,
                            status: location.state.data.status,
                            subtotaladd: location.state.data.subtotaladd,
                            subtotal: location.state.data.subtotal,
                            discount: location.state.data.discount,
                            total: location.state.data.total,
                            coupon: location.state.data.coupon,
                            ipaddress: location.state.data.ipaddress
                        }

                        const invoice = await getInvoice(dataorder);
                        //navigate('/clientarea/hosting/')

                        //navigate(`/clientarea/invoices/${invoice.invoiceid}`, {state: {...invoice, ...{payments: client.payments, paymentmethod: client.pgdefault.gateway, client: client}}});
                        navigate(`/clientarea/invoices/${invoice.invoiceid}/`);

                    }

                }

                //obtener servicios
                //const fullserv = await getServicesClient();
                //console.log('fullserv', fullserv)
                //setServices(fullserv);
                //console.log('domainds ', fullserv.domains)
                //setDomains(fullserv.domains);

                //setFilteredServices(fullserv.services);

            }
            //console.log('cliente data', client);
        }

        isClient();

    }, []);

    /*useEffect(() => {
        setFilteredData(serviceStatus === 'all' ? fullservices.services : fullservices.services.filter(dt => dt.serviceStatus === serviceStatus));

    }, [serviceStatus])*/

    return (
        <>
            <Helmet>
                <title>Área de Cliente | Neothek</title>
            </Helmet>
            {firstname ?
                <Typography variant='body2' color="textSecondary" style={{ textAlign: 'center' }}>Hola {firstname} {lastname}</Typography>
                :
                <SpinnerLoad />
            }

        </>
    )



}

export default Dashboard
