import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography, Grid, Divider, FormHelperText, Checkbox } from '@material-ui/core';
import { authUser } from '../../services/User';
import { saveAuth } from '../../hooks/useAuth';
import { getPublicIp } from '../../utils/index';

function LoginComponent(props) {

  const navigate = useNavigate();

  const [errValidUser, setErrValidUser] = useState(null)

  const location = useLocation();
  const idProduct = location.state.data.idproduct;
  const nameProduct = location.state.data.nameproduct;
  const priceProduct = location.state.data.priceproduct;
  const addons = location.state.data.addons;
  const subTotalAdd = location.state.data.subtotaladd;
  const subtotal = location.state.data.subtotal;
  const discount = location.state.data.discount;
  const total = location.state.data.total;
  const coupon = location.state.data.coupon;
  const domain = location.state.data.domain;
  const pricedomain = location.state.data.pricedomain;
  const pricerenewdomain = location.state.data.pricerenewdomain;
  const eventDomain = location.state.data.eventdomain;
  const billingcycle = location.state.data.billingcycle;
  const paymentmethod = null;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">

          <Formik
            initialValues={{
              email: '',
              password: '',
              policy: false
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('E-mail inválido').max(255).required('Email es requerido'),
              password: Yup.string().max(255).required('Contraseña es requerida'),
              policy: Yup.boolean().oneOf([true], 'Este campo debe estar marcado')
            })}

            onSubmit={async (val, actions) => {

              const res = await authUser(val.email, val.password);
              const ip = await getPublicIp();

              const order = {
                idproduct: idProduct,
                nameproduct: nameProduct,
                priceproduct: priceProduct,
                addons: addons,
                subtotaladd: subTotalAdd,
                subtotal: subtotal,
                discount: discount,
                total: total,
                coupon: coupon,
                domain: domain,
                pricedomain: pricedomain,
                pricerenewdomain: pricerenewdomain,
                eventdomain: eventDomain,
                billingcycle: billingcycle,
                paymentmethod: paymentmethod,
                ipaddress: ip,
                status: 'buyinprogress'
              }


              if (res.status === 1) {
                setErrValidUser(null);
                saveAuth(res);

                navigate('/clientarea/dashboard', { state: { data: order } });

              } else {
                //console.log(res.mensaje)
                actions.setSubmitting(false)
                setErrValidUser(res.message)

                actions.resetForm()

              }


            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>

                <Grid container spacing={1}>
                  {errValidUser &&
                    <Box>
                      <Typography sx={{ color: 'red' }} variant="h6">
                        {errValidUser}
                      </Typography>
                    </Box>
                  }

                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Correo electrónico"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Introduce tu contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      size="small"
                    />
                  </Grid>


                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Box mt={2} mb={2} >
                      <Typography variant='h4'>Sumario de Pedido</Typography>
                      <Divider />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item sm={6} xs={9}>
                        {domain === '' ? <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}>{nameProduct}</Typography>
                          :
                          <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}>{nameProduct} - {domain}</Typography>
                        }
                      </Grid>
                      <Grid item sm={6} xs={3}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem', textAlign: 'right' }}>$ {priceProduct} USD</Typography>
                      </Grid>

                      <Grid item sm={6} xs={9}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}>Complementos</Typography>
                      </Grid>
                      <Grid item sm={6} xs={3}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem', textAlign: 'right' }}>$ {subTotalAdd} USD</Typography>
                      </Grid>

                      <Grid item xs={12} sx={{ borderTop: '1px solid #e3e8ee' }} style={{ marginTop: '10px', paddingTop: '0px' }}></Grid>

                      <Grid item sm={6} xs={8}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}><strong>Subotal</strong></Typography>
                      </Grid>
                      <Grid item sm={6} xs={4}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem', textAlign: 'right' }}><strong>$ {subtotal} USD</strong></Typography>
                      </Grid>

                      {(coupon && discount !== 0.00) &&
                        <>
                          <Grid item sm={6} xs={8}>
                            <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}><strong>Cupón: {coupon.code}</strong></Typography>
                          </Grid>
                          <Grid item sm={6} xs={4}>
                            <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem', textAlign: 'right' }}><strong>$ -{discount} USD</strong></Typography>
                          </Grid>
                        </>
                      }

                      <Grid item sm={6} xs={8}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem' }}><strong>Total</strong></Typography>
                      </Grid>
                      <Grid item sm={6} xs={4}>
                        <Typography color="colornk.grey2" sx={{ fontSize: '1.36rem', textAlign: 'right' }}><strong>$ {total} USD</strong></Typography>
                      </Grid>

                    </Grid>

                    <Divider />

                  </Grid>

                  <Grid item xs={12} md={12} sx={{ mt: 4 }}>

                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1
                      }}
                    >
                      <Checkbox
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        Estoy de acuerdo con los
                        {' '}
                        <Link
                          color="primary"
                          component="a"
                          href="http://www.neothek.com/legal/"
                          underline="always"
                          variant="body2"
                          target="_blank"
                          rel="noopener"
                        >
                          Términos y condiciones de Uso
                        </Link>
                      </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>
                        {errors.policy}
                      </FormHelperText>
                    )}
                  </Grid>



                  <Grid item xs={12} md={12}>
                    <Box sx={{ py: 3 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Completar Pedido
                      </Button>
                    </Box>
                  </Grid>

                </Grid>


              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
}

export default LoginComponent;