import React from 'react';
import { Box, Chip, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TablePagination, Typography } from '@material-ui/core'
import ServiceMoreMenu from './ServiceMoreMenu';

const TableServices = (props) => {

    const { services, page, limit, handlePageChange, handleLimitChange } = props;
    //console.log('services', services)

    return (
        <Box mt={1} mb={3} >
            <TableContainer component={Paper} variant='tbLight-nk'>
                <Table aria-label="simple table" sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Producto/Servicio</strong></TableCell>
                            <TableCell align="left"><strong>Estado</strong></TableCell>
                            <TableCell align="right"><strong>Precio</strong></TableCell>
                            <TableCell align="right"><strong>Vencimiento</strong></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {services.slice(page * limit, page * limit + limit).map((service) => (
                            <TableRow hover key={service.id} variant="rowtbLight-nk">
                                <TableCell component="th" scope="row" variant='celltbLight-nk'>
                                    {service.category} - {service.name} <br></br>
                                    <Typography color="primary" variant='hightlightText-nk'> {service.domain} </Typography>
                                </TableCell>
                                <TableCell align="left" variant='celltbLight-nk'>
                                    {service.status === 'Pending' &&
                                        <Chip color="secondary" size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                    }
                                    {service.status === 'Active' &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                    }
                                    {service.status === 'Completed' &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#abf6f9', color: '#34a7c1' }} />
                                    }
                                    {(service.status === 'Suspended' || service.status === 'Terminated' || service.status === 'Cancelled') &&
                                        <Chip size="small" label={service.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                    }
                                </TableCell>
                                <TableCell align="right" variant='celltbLight-nk'> {service.billingcycle === 'One Time' ? <> ${service.firstpayment}USD </> : <> ${service.amount}USD </> }  - {service.billingcycle}</TableCell>
                                <TableCell align="right" variant='celltbLight-nk'> {service.billingcycle === 'One Time' ? <> </> : <> {service.nextduedate} </> } </TableCell>
                                <TableCell align='rigth'>
                                    <ServiceMoreMenu addons = {service.addons} service={service} />
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={services.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />

        </Box>
    );
}

export default TableServices;