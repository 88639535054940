import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Button, Container, Grid, Typography, MenuItem, TextField, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import StepperCart from '../components/FormsUI/StepperCart'
import VerificarDominio from '../components/verify/VerificarDominio'
import axios from 'axios'
import * as Yup from 'yup'
import { useParams, useNavigate } from 'react-router-dom'
import { getDomainTlds, getProductAvailable } from '../services/Product'
import { getPublicIp, getTimeNow } from '../utils/index'
import appConfig from '../config/main.config'
import SpinnerLoad from '../components/FormsUI/Spinner'
const api = appConfig.url_api;

const SearchDomain = (props) => {
  //const rrr= new URLSearchParams(props.location.search).get("pid")
  //this.props.match.params.id
  //console.log('res', this.props)
  let { pid, cycle } = useParams();
  const navigate = useNavigate();

  const [isProduct, setIsProduct] = useState(false);

  if (cycle === undefined) cycle = '';

  const [domainRegister, setDomainRegister] = useState('');
  const [domainRegStatus, setDomainRegStatus] = useState('');
  const [tldRegister, setTldRegister] = useState('.com');

  const [domainTransfer, setDomainTransfer] = useState('');
  const [domainTraStatus, setDomainTraStatus] = useState('');
  const [tldTransfer, setTldTransfer] = useState('.com');

  const [product, setProduct] = useState(pid);
  const [billingcycle, setBillingcycle] = useState(cycle);

  //selectDomain
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([
    { label: "Cargando...", value: ".com" }
  ])

  //tabs
  const [valueTab, setValueTab] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  }


  async function handleWhoisDomain(dom, ext) {
    const ipv4 = await getPublicIp()
    const today = getTimeNow('yyyy-mm-dd H:MM:ss')
    const domain = dom + ext

    const data = {
      domain: domain,
      date: today,
      ip: ipv4
    };

    axios.post(`${api}/logs/whois/`, data)
      .then(function (response) {
        //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });

  }

  const handleTransferDomain = (dom, ext) => {
    const nombredominio = {
      domain: dom,
      extension: ext
    };
    const domext = dom + ext;

    axios.post(`${api}/domain/isavailable/`, nombredominio)
      .then(function (response) {
        //console.log(response);
        setDomainTransfer(domext);
        setDomainTraStatus(response.data[domext]["status"]);
        setTldTransfer(ext)

      })
      .catch(function (error) {
        //console.log(error);
      });

    //setDomainTransfer(domext);
    //setDomainTraStatus('regthroughus');
    //setDomainTraStatus('available');
    //setTld(ext);

  };

  const handleVerifyDomain = (dom, ext) => {
    const nombredominio = {
      domain: dom,
      extension: ext
    };
    const domext = dom + ext;

    axios.post(`${api}/domain/isavailable/`, nombredominio)
      .then(function (response) {
        //console.log(response);
        setDomainRegister(domext);
        setDomainRegStatus(response.data[domext]["status"]);
        setTldRegister(ext)

      })
      .catch(function (error) {
        //console.log(error);
      });

    //setDomainRegister(domext);
    //setDomainRegStatus('regthroughus');
    //setDomainRegStatus('available');
    //setTldRegister(ext);

  };

  //Formik

  const initialValues = {
    dominioform: '',
    extensionform: '.com'
  }
  const validationSchema = Yup.object().shape({
    dominioform: Yup.string()
      .required("Requerido")
      .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios')
  })
  const onSubmitReg = (values, props) => {
    const dom = values.dominioform;
    const ext = values.extensionform;
    handleVerifyDomain(dom, ext);
    handleWhoisDomain(dom, ext);
    //props.resetForm()
  }
  const onSubmitTransfer = (values, props) => {
    const dom = values.dominioform;
    const ext = values.extensionform;
    handleTransferDomain(dom, ext);
    handleWhoisDomain(dom, ext);
  }

  //Formik Domain Own
  const initialValuesOwn = {
    dominioform: '',
    tldform: ''
  }
  const validationSchemaOwn = Yup.object().shape({
    dominioform: Yup.string()
      .required('Requerido')
      .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios'),
    tldform: Yup.string()
      .required('Requerido')
      .matches(/^[aA-zZ0-9.]+$/, 'Únicamente se permite letras y números sin espacios')
      .max(20, 'Se acepta máximo 20 dígitos')
  })
  const onSubmitOwn = (values, props) => {
    const dom = values.dominioform;
    const tld = values.tldform;
    const domain = dom + tld;
    navigate('/cart/product/revorder/', { state: { dominio: domain, product: product, billingcycle: billingcycle, tld: tld, eventDomain: 'domainown' } });

  }

  useEffect(() => {
    let unmounted = false;
    async function getTLDs() {
      //const response = await fetch("/domains/extensions/")
      //const body = await response.json()
      const body = await getDomainTlds();

      if (!unmounted) {
        //console.log("entrando a la condicional, valor "+unmounted);
        setItems(body.map((domain) => ({ label: domain.extension, value: domain.extension })))
        setLoading(false)
      }

      const existProd = await getProductAvailable(pid);
      //console.log('salio a buscar product', existProd);

    }

    //verificarProducto existe, sino redireciona a store
    async function isProduct() {
      const product = await getProductAvailable(pid);
      if (product) { //si existe, verificar si exige dominio
        //console.log('salio a buscar product', product);
        if (product.showdomain === 1) { //si necesita dominio
          setIsProduct(true);
          getTLDs();
        } else {
          navigate('/cart/product/revorder/', { state: { dominio: '', product: product.id, billingcycle: billingcycle, tld: '', eventDomain: 'nodomain' } })
        }

      } else { //si product no existe redirige a store
        setIsProduct(false);
        navigate('/store/')
      }

    }

    isProduct();
    //getTLDs();
    return () => {
      unmounted = true;
    }
  }, [])


  if (isProduct) {
    return (
      <>
        <Helmet>
          <title>Buscar Dominio | Neothek</title>
        </Helmet>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="md">
            <Grid item xs={12} md={12}>
              <StepperCart step={0} />
            </Grid>
          </Container>

          <Container maxWidth="md" variant='cont-nk'>
            <Grid container>
              {/* <Grid item xs={12} md={12}>
                <StepperCart step={0} />
              </Grid> */}

              {/* tabs */}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={valueTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                      <Tab label="Registrar un nuevo dominio" value="1" sx={{ fontSize: '11px' }} />
                      <Tab label="Transferir Dominio" value="2" sx={{ fontSize: '11px' }} />
                      <Tab label="Ya tengo dominio" value="3" sx={{ fontSize: '11px' }} />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {/* registrar dominio */}
                    <Box sx={{ py: 4 }}>
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitReg}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item xs={8}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                    {items.map(({ label, value }) => (
                                      <MenuItem key={value} value={value}>
                                        {label}
                                      </MenuItem>
                                    ))}

                                  </Field>

                                </Grid>

                                <Grid container dir="rtl">
                                  <Button type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                    Comprobar
                                  </Button>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                        <VerificarDominio domainstatus={domainRegStatus} inputdomain={domainRegister} product={product} billingcycle={billingcycle} tld={tldRegister} eventDomain='domainregister'></VerificarDominio>
                      </Grid>
                    </Box>
                    {/* registrar dominio */}
                  </TabPanel>
                  <TabPanel value="2">
                    {/* transferir dominio */}
                    <Box sx={{ py: 4 }}>
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitTransfer}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item xs={8}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field as={TextField} variant="outlined" name='extensionform' label='Extensión' select fullWidth>

                                    {items.map(({ label, value }) => (
                                      <MenuItem key={value} value={value}>
                                        {label}
                                      </MenuItem>
                                    ))}

                                  </Field>

                                </Grid>

                                <Grid container dir="rtl">
                                  <Button type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                    Transferir
                                  </Button>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                        <VerificarDominio domainstatus={domainTraStatus} inputdomain={domainTransfer} product={product} billingcycle={billingcycle} tld={tldTransfer} eventDomain='domaintransfer'></VerificarDominio>
                      </Grid>
                    </Box>
                    {/* transferir dominio */}
                  </TabPanel>
                  <TabPanel value="3">
                    {/* yatengo dominio */}
                    <Box sx={{ py: 4 }}>
                      <Grid item xs={12} md={12}>
                        <Formik initialValues={initialValuesOwn} validationSchema={validationSchemaOwn} onSubmit={onSubmitOwn}>
                          {(props) => (

                            <Form>
                              <Grid container spacing={2}>

                                <Grid item xs={8}>
                                  <Field as={TextField} variant="outlined" name='dominioform' label='Dominio' placeholder='tudominio' fullWidth
                                    error={props.errors.dominioform && props.touched.dominioform}
                                    helperText={<ErrorMessage name='dominioform' />} required />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field as={TextField} variant="outlined" name='tldform' label='Extensión' placeholder='.com' fullWidth
                                    error={props.errors.tldform && props.touched.tldform}
                                    helperText={<ErrorMessage name='tldform' />} required />
                                </Grid>

                                <Grid container dir="rtl">
                                  <Button type='submit' variant='contained' color='primary' sx={{ my: 2 }}>
                                    Usar
                                  </Button>
                                </Grid>

                              </Grid>
                            </Form>

                          )}
                        </Formik>

                      </Grid>
                    </Box>
                    {/* yatengo dominio */}
                  </TabPanel>
                </TabContext>
              </Box>





            </Grid>


          </Container>
        </Box>
      </>
    )
  } else {
    return (
      <SpinnerLoad />
    )
  }



}

export default SearchDomain;
