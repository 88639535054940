import React, { useState, useEffect } from 'react';
import { useLocation, Link as RouterLink, useParams } from 'react-router-dom';
import { Chip, Link, Grid, Typography, Button, Container, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PayPalButton from '../components/payments/PayPalButton';
import CheckoutButton from '../components/payments/2CheckoutButton';
import StripeButton from '../components/payments/StripeButton';
import PayuButton from '../components/payments/PayuButton';
import updatePaymentClient from '../services/Payment';
import getInvoiceClient from '../services/InvoiceClient';
import downloadInvoicePdf from '../services/InvoicePdf';
import DownloadIcon from '@material-ui/icons/Download';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import Logo from '../components/Logo';
import SpinnerLoad from '../components/FormsUI/Spinner';

const Invoice = () => {
    //console.log('ingresa a Invoice')
    const { id } = useParams();
    const [payment, setPayment] = useState(null);
    const [invoice, setInvoice] = useState(null);


    const handleChangePayment = async (ev) => {
        //console.log('valor payment', ev.target.value)

        const values = {
            uid: invoice.client_id,
            invoiceid: invoice.invoiceid,
            paymethod: ev.target.value
        }

        const pgupdated = await updatePaymentClient(values);

        setPayment(ev.target.value)

    }

    const handleInvoicePdf = async () => {
        const id = invoice.invoiceid;
        const res = await downloadInvoicePdf(id);

    }


    useEffect(() => {

        const getInvoice = async () => {
            const data = await getInvoiceClient(id);

            //console.log('invoice traido BD', data)

            setInvoice(data);
            //setPayment(data.pgdefault.gateway);
            setPayment(data.invoicepayment)
        }

        getInvoice();

    }, [])


    if (invoice === null || payment === null) {
        return ( <SpinnerLoad /> )
    } else {
        return (
            <>
                <Helmet>
                    <title>{`Invoice #${invoice.invoiceid}`} | Neothek</title>
                </Helmet>
                <Box>
                    <Container maxWidth="md" variant='cont-nk' sx={{ pb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={8}>
                                <Grid container sx={{ backgroundColor: '#f8f8f8' }} mt={3} mb={3} pb={2}>

                                    <Grid item xs={8}>
                                        <Box sx={{ paddingTop: '16px', paddingLeft: '10px' }}>
                                            <Typography variant='h4' sx={{ display: 'inline', pr: 1 }}>Nota #{invoice.invoiceid}</Typography>
                                            {invoice.invoicestatus === 'Unpaid' &&
                                                <Chip color="secondary" size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                            }
                                            {invoice.invoicestatus === 'Paid' &&
                                                <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                            }
                                            {invoice.invoicestatus === 'Cancelled' &&
                                                <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                            }
                                            {invoice.invoicestatus === 'Refunded' &&
                                                <Chip size="small" label={invoice.invoicestatus} sx={{ borderRadius: 1, marginTop: '-4px', height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                            }
                                        </Box>

                                    </Grid>
                                    <Grid item xs={4} dir='rtl'>
                                        <Button variant="outlined" onClick={handleInvoicePdf} startIcon={<DownloadIcon sx={{ mr: 0, ml: 0 }} />} endIcon={<PdfIcon sx={{ ml: 0, mr: 2, color: '#686868' }} />} sx={{ mt: 1.7, mr: 2, padding: '4px 5px' }} ></Button>
                                    </Grid>
                                </Grid>

                                <Box>
                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <Typography variant='subtitule1' color='primary'>Pagar a:</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.invoicepayto}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='subtitule1' color='primary'>Emitido a:</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.client_companyname}</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.client_firstname} {invoice.client_lastname}</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.client_address1}</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.client_city} {invoice.client_state} {invoice.client_postcode}</Typography>
                                            <Typography variant='body2' color="grey.500">{invoice.client_country}</Typography>
                                        </Grid>
                                    </Grid>

                                </Box>
                                <Box mt={5} mb={3} >
                                    <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Productos/Servicios</Typography>

                                    <TableContainer component={Paper} >
                                        <Table aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Descripción</strong></TableCell>
                                                    <TableCell align="right"><strong>Importe</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow hover >
                                                    <TableCell component="th" scope="row" variant='celltbLight-nk'>
                                                        {invoice.productdesc}
                                                    </TableCell>
                                                    <TableCell align="right" variant='celltbLight-nk'>${invoice.productamount}USD</TableCell>
                                                </TableRow>

                                                {invoice.promo &&
                                                    <TableRow hover >
                                                        <TableCell component="th" scope="row" variant='celltbLight-nk'>
                                                            {invoice.promo.description}
                                                        </TableCell>
                                                        <TableCell align="right" variant='celltbLight-nk'>${invoice.promo.amount}USD</TableCell>
                                                    </TableRow>
                                                }

                                                {invoice.addons.map((addon) => (
                                                    <TableRow hover key={addon.id}>
                                                        <TableCell component="th" scope="row" variant='celltbLight-nk'>
                                                            {addon.description}
                                                        </TableCell>
                                                        <TableCell align="right" variant='celltbLight-nk'>${addon.amount}USD</TableCell>
                                                    </TableRow>
                                                ))}

                                                <TableRow>
                                                    <TableCell variant='celltbLight-nk'> <strong>Total</strong></TableCell>
                                                    <TableCell align="right" variant='celltbLight-nk'>${invoice.total}USD</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Box>

                                {/* transactions*/}

                                <Box mt={5} mb={3}>
                                    <Typography variant='h5' sx={{ mb: 2 }} color='primary'>Transacciones</Typography>
                                    <TableContainer component={Paper} >
                                        <Table aria-label="simple table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong>Fecha</strong></TableCell>
                                                    <TableCell><strong>Método/Gateway</strong></TableCell>
                                                    <TableCell><strong>ID Transacción</strong></TableCell>
                                                    <TableCell align="right"><strong>Total</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoice.transactions.length !== 0 &&
                                                    <>
                                                        {invoice.transactions.map((trans) => (
                                                            <TableRow hover key={trans.id}>
                                                                <TableCell component="th" scope="row" variant='celltbLight-nk'>{trans.date}</TableCell>
                                                                <TableCell variant='celltbLight-nk'>{trans.gateway}</TableCell>
                                                                <TableCell variant='celltbLight-nk'>{trans.transid}</TableCell>

                                                                {trans.amountin !== 0 && <TableCell align="right" variant='celltbLight-nk'>${trans.amountin}USD</TableCell>}
                                                                {trans.amountout !== 0 && <TableCell align="right" variant='celltbLight-nk'>$-{trans.amountout}USD</TableCell>}

                                                            </TableRow>
                                                        ))}
                                                    </>
                                                }
                                                {invoice.transactions.length === 0 &&
                                                    <TableRow hover>
                                                        <TableCell colSpan={4} variant='celltbLight-nk' align='center'>No se encontraron transacciones relacionadas</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>



                            </Grid>

                            <Grid item xs={12} sm={12} md={4} align='center'>

                                {invoice.invoicestatus === 'Unpaid' &&
                                    <>
                                        <Typography variant='h5' sx={{ mt: 4, mb: 2, ml: 2, textAlign: 'left' }} color='primary'>Pagar a través de:</Typography>
                                        <Box>
                                            <FormControl sx={{ m: 1, width: 250 }}>
                                                <InputLabel id="labelPayment" sx={{ ml: 1.7, mt: -1 }}>Método de Pago</InputLabel>
                                                <Select
                                                    labelId="labelPayment"
                                                    onChange={handleChangePayment}
                                                    value={payment}
                                                    input={<OutlinedInput label="Método de Pago" />}
                                                    size="small"
                                                >
                                                    {invoice.payments.map((pay) => (
                                                        <MenuItem
                                                            key={pay.id}
                                                            value={pay.gateway}
                                                        >
                                                            {pay.value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                <Box mt={4}>
                                                    {payment === 'banktransfer' &&
                                                        <Typography variant='body2' color="textSecondary">Puede pagar a través de depósito, transferencia bancaria o pago por QR. Encuentre las cuentas bancarias habilitadas <Link
                                                            color="primary"
                                                            component="a"
                                                            href="https://www.neothek.com/es-bo/efectivo/"
                                                            underline="always"
                                                            variant="body2"
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            aquí
                                                        </Link></Typography>
                                                    }
                                                    {payment === 'paypal' &&
                                                        <PayPalButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'tco' &&
                                                        <CheckoutButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'stripe' &&
                                                        <StripeButton invoice={invoice} paymethod={payment} />
                                                    }
                                                    {payment === 'payu' &&
                                                        <PayuButton invoice={invoice} paymethod={payment} />

                                                    }
                                                    {payment === 'mailin' &&
                                                        <><Typography variant='body2' color="textSecondary">Para pagar en efectivo por favor visite: <Link
                                                            color="primary"
                                                            component="a"
                                                            href="https://www.neothek.com/efectivo/"
                                                            underline="always"
                                                            variant="body2"
                                                            target="_blank"
                                                            rel="noopener"
                                                        >
                                                            https://www.neothek.com/efectivo/
                                                        </Link></Typography>
                                                            <Typography variant='body2' color="textSecondary">Referencia de Pago: {invoice.invoiceid}</Typography></>
                                                    }
                                                </Box>
                                            </FormControl>
                                        </Box>

                                    </>
                                }


                                {/*<Box mt={5}>
                                        <Button variant="outlined" onClick={handleInvoicePdf} startIcon={<DownloadIcon />} sx={{m: 1, width: 250}} >Descargar</Button>
                                    </Box> */}

                            </Grid>


                        </Grid>
                    </Container>
                </Box>

            </>

        )
    }

}

export default Invoice;