import React from 'react';
import { Button, Container, Chip, Box, Grid, Typography, Divider, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBack';
import SpinnerLoad from '../components/FormsUI/Spinner';

const Addons = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    let service = null;

    if (location.state !== null) {
        service = location.state.service;
        //console.log('services', service);
    }

    const handleBack = () => {
        navigate(`/clientarea/products/`);
    }

    if (service === null) {
        return (
            <SpinnerLoad />
        )
    } else {
        return (
            <>
                <Helmet>
                    <title>Área de Cliente | Neothek</title>
                </Helmet>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center'
                    }}
                >

                    <Container maxWidth="lg" variant='cont-nk'>

                        <Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box mt={3} mb={3} >
                                        <Typography> <span style={{fontSize: '15px', fontWeight: '700', textTransform: 'uppercase'}}> Complementos :</span> <span style={{color: '#9e9e9e'}}> {service.category} - {service.name} {service.domain && <span>({service.domain})</span> } </span> </Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box mt={1} mb={3} >
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" sx={{ minWidth: 650 }} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Producto/Servicio</strong></TableCell>
                                                        <TableCell align="left"><strong>Estado</strong></TableCell>
                                                        <TableCell align="left"><strong>Precio</strong></TableCell>
                                                        <TableCell align="right"><strong>Vencimiento</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {service.addons.map((addon) => (
                                                        <TableRow hover key={addon.id} variant="rowtbLight-nk">
                                                            <TableCell component="th" scope="row" variant='celltbLight-nk' >
                                                                {addon.name}
                                                            </TableCell>
                                                            <TableCell align="left" variant='celltbLight-nk' >
                                                                {addon.status === 'Pending' &&
                                                                    <Chip color="secondary" size="small" label={addon.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#E3E8EE', color: '#53566B' }} />
                                                                }
                                                                {addon.status === 'Active' &&
                                                                    <Chip size="small" label={addon.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#cbf4c9', color: '#268717' }} />
                                                                }
                                                                {addon.status === 'Completed' &&
                                                                    <Chip size="small" label={addon.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#abf6f9', color: '#34a7c1' }} />
                                                                }
                                                                {(addon.status === 'Suspended' || addon.status === 'Terminated' || addon.status === 'Cancelled') &&
                                                                    <Chip size="small" label={addon.status} sx={{ borderRadius: 1, height: '20px', backgroundColor: '#F8DADB', color: '#B72136' }} />
                                                                }
                                                            </TableCell>
                                                            <TableCell align="left" variant='celltbLight-nk' >${addon.amount}USD - {addon.billingcycle}</TableCell>
                                                            <TableCell align="right" variant='celltbLight-nk' >{addon.nextduedate}</TableCell>

                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Box>

                                </Grid>

                                <Grid item xs={12} sx={{pb: 2}}>
                                   <Button variant='contained' color='primary' startIcon={<BackIcon />}  onClick={handleBack}>  Atrás</Button>                             
                                </Grid>

                            </Grid>

                        </Box>

                    </Container>

                </Box>
            </>
        )
    }

}

export default Addons;