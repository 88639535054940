import ReactPhoneInput from "react-phone-input-2";
//import 'react-phone-input-2/lib/style.css'
//import 'react-phone-input-2/lib/high-res.css'
//import 'react-phone-input-2/lib/plain.css'
import 'react-phone-input-2/lib/material.css'
import { useField, useFormikContext } from 'formik'
import { useState } from "react";
import React from "react";

const SelectPhone = ( {name, ...otherProps} ) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name)

  const [phone, setPhonenum] = useState('');

  function setPhone(p){
    let val = p.phone
    setPhonenum(p)
    setFieldValue(name, val)
  }

  const handleChange = evt => {
    setFieldValue(name, evt.target.value);
  }

  const configTextField = {
    ...field,
    ...otherProps
  }

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return(

    <React.Fragment>
      <ReactPhoneInput
        {...configTextField}
        onChange={phone => setPhone({ phone })}
      />
      {meta.error && meta.touched ? ( <div>{meta.error}</div>) : null}
      </React.Fragment>
    
  )
}

export default SelectPhone

