import React from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, List, Link, ListItem } from '@material-ui/core'

const Store = () => {
    
    return (
        <>
            <Helmet>
                <title>Neothek | Store</title>
            </Helmet>
            <Box>
                <Container maxWidth="sm">

                    <Box sx={{ mb: 2, mt: 3 }}>
                    <Typography
                        color="textPrimary"
                        variant="h1"
                        align="center"
                    >
                        Store
                    </Typography>
                    
                    </Box>

                    <Box>
                    <List>
                        <h3 variant="h2">Planes Hosting</h3>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/1/billingcycle/biennially/"
                            variant="h6"
                            >
                                Plan Inicial
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/2/"
                            variant="h6"
                            >
                                Plan Webmaster
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/3/"
                            variant="h6"
                            >
                                Plan Profesional
                            </Link>
                        </ListItem>

                        <h3 variant="h2">Planes Hosting Ilimitado</h3>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/10/"
                            variant="h6"
                            >
                                Plan Power
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/11/"
                            variant="h6"
                            >
                                Plan Ultra
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link
                            component={RouterLink}
                            to="/cart/product/12/"
                            variant="h6"
                            >
                                Plan Ultimate
                            </Link>
                        </ListItem>
                        
                    </List>
                    </Box>

                    {/*<Box>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <DnsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Inbox" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <DnsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Drafts" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box> */}


                </Container>
            </Box>
        </>
    )
}

export default Store