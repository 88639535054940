import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function updatePaymentClient(val) {
    //console.log('Ingresa a funcion pago')
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    //const api = `/clients/pgdefault/${uid}`;//25474
    const api = `${apiserv}/clients/pgdefault/${val.uid}`;

    const payinvoice = axios.put(api, val, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            return res.data;
        })
        .catch((error) => {

            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                /*console.log('response error');
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers); */
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                /*console.log('request error')
                console.log(error.request);*/
            } else {
                // Something happened in setting up the request that triggered an Error
                //console.log('Error', error.message);
            }
            //console.log(error.config);

        });

    return payinvoice;

}