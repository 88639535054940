import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export const authUser = async (email, pass) => {
    //console.log('auth user')

    const datasend = {
        email: email,
        password: pass
    }

    const res = await axios.post(`${apiserv}/users/login/`, datasend)
        .then(function (response) {
            //console.log(response)
            return response.data;
        })
        .catch(function (error) {
            //console.log(error);
        });

    return res
};

export const createUser = async (data) => {
    //console.log('datos enviados crear usuario', data)
    const res = await axios.post(`${apiserv}/users`, data)
        .then(resp => { return { status: true } })
        .catch(err => { return { status: false } })

    return res;
}

