import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function setPaydout2CO(details, invoice){
    //console.log('data enviada a paydout 2co BD', details, invoice)

    const data = {
      datagateway: details,
      datainvoice: invoice
    }
    
    const res = await axios.post(`${apiserv}/gateways/2checkout`, data)
        .then(function (response) {
            //console.log(response)
            return response.data;
          })
        .catch(function (error) {
            //console.log('da error', error);
          });

    return res
}