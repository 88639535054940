import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function iniSessionStripe(details) {
    //console.log('data enviada inisession Stripe BD', details)
    const data = {
        invoiceid: details.invoiceid,
        total: details.total,
        userid: details.client_id
    }

    const res = await axios.post(`${apiserv}/gateways/stripe/create-checkout-session`, data)
        .then(function (response) {
            //console.log('response dentro de ini', response)
            return response.data;
        })
        .catch(function (error) {
            //console.log('da error', error);
        });

    return res
}