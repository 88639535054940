import {
    NavLink as RouterLink,
    matchPath,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';

const NavItem = ({
    href,
    icon: Icon,
    title,
    ...rest
}) => {
    const location = useLocation();

    const active = href ? !!matchPath({
        path: href,
        end: false
    }, location.pathname) : false;

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                py: 0,
                outlineWidth: '0px'
            }}
            {...rest}
        >
            <Button
          component={RouterLink}
          sx={{
            color: '#99a2b4',
            fontWeight: '500',
            fontSize: '1.2rem',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'uppercase',
            width: '100%',
            ...(active && {
              color: 'primary.main'
            }),
            '& svg': {
              mr: 1
            },
            '&:hover': {color: 'primary.main'},
            '&:focus': {color: 'primary.main'}
          }}
          to={href}
        >
          {Icon && (
            <Icon size="20" />
          )}
          <span>
            <strong> {title} </strong>
          </span>
        </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
