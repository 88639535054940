import React from 'react';
import ReactDOM from "react-dom"
import { Container } from "@material-ui/core";
import setLogPayment from '../../services/PaymentPayPal';
import { useNavigate } from 'react-router-dom';
import setPaydoutPayPal from '../../services/PaymentPayPal';

const PayPalButtonApp = window.paypal.Buttons.driver("react", { React, ReactDOM });
const PayPalButton = (props) => {

    const navigate =  useNavigate();
    const datainvoice = {
      userid: props.invoice.client_id,
      currency: 0,
      gateway: props.paymethod,
      description: 'Invoice Payment',
      amountin: props.invoice.total,
      invoiceid: props.invoice.invoiceid
    }

    const invoiceid = props.invoice.invoiceid;
    const createOrder = (data, actions) =>{
        return actions.order.create({
          purchase_units: [
            {
              description: `Neothek - Nota nº ${invoiceid}`,
              amount: {
                value: props.invoice.total,
              }
            },
          ],
        });
      };
      const onApprove = (data, actions) => {
        //return actions.order.capture();
        return actions.order.capture().then(function(details) {
            const info = {
              datainvoice: datainvoice,
              datagateway: details
            }

            const paydout = setPaydoutPayPal(info);
          

            if(details.status === 'COMPLETED'){
                navigate('/clientarea/success-payment/');
            }
    
          });
      };
      
    return (
       
            <Container>
                <PayPalButtonApp
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                  />
            </Container>
          
     
    );
};

export default PayPalButton;