import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function getInvoice(orderdata) {
  //console.log('data enviada a invoice save', orderdata) 
  const res = await axios.post(`${apiserv}/invoices/`, orderdata)
    .then(function (response) {
      //console.log('respuesta de invoice save', response)
      return response.data;
    })
    .catch(function (error) {
      //console.log('da error', error);
    });

  return res
}