import { Grid, Typography, Container, Box, Button, Divider, Checkbox, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StepperCart from '../../components/FormsUI/StepperCart';
import { useNavigate, useLocation } from "react-router-dom";
import CheckBoxOutline from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCycleProduct, getProduct, getAddons, getMinAddons, getValIniProd, getIsFreeDomain } from '../../services/Product';
import getPriceTld from '../../services/Pricing';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { searchCoupon, calculateDiscount } from '../../services/Promotion';
import CycleRadio from './components/CycleRadio.component';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NextIcon from '@material-ui/icons/ArrowForward';
import SpinnerLoad from '../../components/FormsUI/Spinner';

const ReviewOrder = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let idProduct = '';
    let dominio = '';
    let billingcycle = '';
    let tld = '';
    let eventDomain = 'domainregister'

    if (location.state !== null) {
        idProduct = location.state.product;
        dominio = location.state.dominio;
        billingcycle = location.state.billingcycle;
        tld = location.state.tld;
        eventDomain = location.state.eventDomain;
    }
    if (billingcycle === '') billingcycle = 'annually'

    const currency = 1; //permitir cambio moneda

    const [product, setProduct] = useState({});
    const [cycleProduct, setCycleProduct] = useState([]);
    const [addons, setAddons] = useState([]);
    const [minAddons, setMinAddons] = useState([]);
    const [priceProdCurrent, setPriceProdCurrent] = useState(0.00);
    const [cycleProdCurrent, setCycleProdCurrent] = useState(billingcycle);
    const [priceDomain, setPriceDomain] = useState(0.00);
    const [priceRenewDomain, setPriceRenewDomain] = useState(0.00);
    const [pricingTld, setPricingTld] = useState({});
    const [pricingRenewTld, setPricingRenewTld] = useState({});
    const [subTotalAdd, setSubTotalAdd] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [subtotal, setSubtotal] = useState(0.00);

    //console.log('location', location.state.product)

    //style calButton
    //const [variantCal, setVariantCal] = useState('');

    //coupon
    const [coupon, setCoupon] = useState(null);
    const [validCoupon, setValidCoupon] = useState(null);
    const [discount, setDiscount] = useState(0.00);
    const couponValue = {
        coupon: ''
    };
    const couponValidation = Yup.object().shape({
        coupon: Yup.string()
            .matches(/^[aA-zZ0-9-]+$/, 'Únicamente se permite letras y números sin espacios'),
    });
    const onCouponSubmit = async (values, props) => {
        //console.log('dio click', values.coupon);
        const coupon = await searchCoupon(values.coupon);
        //console.log('coupon', coupon);
        props.resetForm();
        if (coupon.valid === true) {
            setCoupon(coupon);
            const descuento = calculateDiscount(coupon, idProduct, priceProdCurrent, cycleProdCurrent);
            setDiscount(descuento);
            setValidCoupon(true);

            //actualizar total
            
            setTotal((subtotal - descuento).toFixed(2));

        } else {
            setValidCoupon(false)
        }

        //props.resetForm();
    }

    const handleDeleteCoupon = () => {
        setDiscount(0.00);
        setCoupon(null);
        setTotal(subtotal);
    }

    const searchPriceDomain = (cycle) => {
        let priceDomain = 0.00;

        if (eventDomain === 'domainregister' || eventDomain === 'domaintransfer') {
            const typeFreeDomain = getIsFreeDomain(tld, cycle, product);
            if (typeFreeDomain.isFree) {
                setPriceDomain(0.00);
                priceDomain = 0.00;
            } else {
                setPriceDomain(pricingTld.annually);
                priceDomain = pricingTld.annually;
            }
            if (typeFreeDomain.type === 'on') {
                setPriceRenewDomain(0.00)
            } else { //es once o void
                setPriceRenewDomain(pricingRenewTld.annually);
            }
        }

        return priceDomain;
    }

    const setPricesIni = (price, cycle, pricedomain) => {
        setPriceProdCurrent(parseFloat(price));
        setCycleProdCurrent(cycle);

        let newtotal = 0;
        const periodsel = cycle;
        let newprice = (parseFloat(price));

        let newsubtotaladd = getPriceAllAddonCheck(periodsel);

        //se suma precio product + addons check + dominio
        newtotal = (newprice + newsubtotaladd + pricedomain).toFixed(2);

        setSubTotalAdd(newsubtotaladd);
        setSubtotal(newtotal);
        setTotal((newtotal-discount).toFixed(2));
    }

    //params @precioProducto @bilingcycle
    const handleSelectCycle = (price, cycle) => {
        setPriceProdCurrent(parseFloat(price));
        setCycleProdCurrent(cycle);

        const priceDom = searchPriceDomain(cycle);

        let newtotal = 0;
        const periodsel = cycle;
        let newprice = (parseFloat(price));

        let newsubtotaladd = getPriceAllAddonCheck(periodsel);

        newsubtotaladd = parseFloat(newsubtotaladd.toFixed(2));

        //console.log('price domain', priceDomain)
        //se suma precio product + addons check + dominio
        newtotal = (newprice + newsubtotaladd + priceDom).toFixed(2);
        //newtotal = newprice + subtotaladd;
        setSubTotalAdd(newsubtotaladd);
        setSubtotal(newtotal);

        //reset coupon
        setCoupon(null);
        setDiscount(0.00);
        setTotal(newtotal);
    }

    const getPriceAllAddonCheck = (period) => {

        let priceadd = 0;
        let newsubtadd = 0;
        addons.map((addon) => {
            if (addon.ischeck === true) {
                priceadd = addon[period];

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    minAddons.map((add) => {
                        if (add.id === addon.id) {
                            priceadd = add.price;
                        }
                    });
                }

                newsubtadd = newsubtadd + priceadd;

            }

        });
        //console.log('new subtadd check all', newsubtadd);
        return newsubtadd;
    }

    const handleCheckAddon = (ev) => {
        let priceadd = 0;
        let idaddcheck = ev.target.value;
        let ischeck = ev.target.checked;
        let newsubtotaladd = 0;

        priceadd = getPriceAddonCheck(idaddcheck, ischeck);

        parseFloat(priceadd)

        if (ev.target.checked) {
            newsubtotaladd = priceadd + subTotalAdd;
            //console.log('precio addon: ', priceadd);
        } else {
            newsubtotaladd = subTotalAdd - priceadd;
        }
        //console.log('newsubtotal ', newsubtotaladd)
        //console.log('newsubtotal 2 ', newsubtotaladd.toFixed(2))

        newsubtotaladd = parseFloat(newsubtotaladd.toFixed(2));
        //console.log('subtotal 2 despues2 ', newsubtotaladd)
        setSubTotalAdd(newsubtotaladd);

        //se suma precio product + addons check + dominio
        let newtotal = (priceProdCurrent + newsubtotaladd + priceDomain).toFixed(2);
        setSubtotal(newtotal);
        setTotal((newtotal-discount).toFixed(2));

    }

    const getPriceAddonCheck = (idaddonp, state) => {

        let priceadd = 0;
        let ischecknew = state;
        let idaddon = parseInt(idaddonp);
        addons.map((addon) => {
            if (addon.id === idaddon) {
                //console.log('cycleselect value: ', cycleselect);
                priceadd = addon[cycleProdCurrent];
                addon.ischeck = ischecknew;

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    minAddons.map((add) => {
                        if (add.id === idaddon) {
                            priceadd = add.price;
                        }
                    });
                }
            }
        });

        return priceadd;
    }

    const passRegister = () => {

        const listaddons = getListAddonsCheck();
        const addons = {
            addons: listaddons
        }

        const orderdata = {
            idproduct: idProduct,
            priceproduct: priceProdCurrent,
            billingcycle: cycleProdCurrent,
            subtotaladd: subTotalAdd,
            subtotal: subtotal,
            total: total,
            discount: discount,
            coupon: coupon,
            nameproduct: product.name,
            domain: dominio,
            pricedomain: priceDomain,
            pricerenewdomain: priceRenewDomain,
            eventdomain: eventDomain
        }
        const orderdatafull = { ...orderdata, ...addons }

        navigate('/cart/product/checkout/', { state: { data: orderdatafull } });

    }

    function getListAddonsCheck() {

        const listaddons = [];
        const period = cycleProdCurrent; //con cicle select de producto

        addons.map((addon) => {
            if (addon.ischeck === true) {
                let priceadd = addon[period];
                let periodeng = period;
                let billingcycleadd = addon.billingcycle;

                //console.log('valor de billingcycle add check es', billingcycleadd)

                if (billingcycleadd === 'Monthly' || billingcycleadd === 'Quarterly' || billingcycleadd === 'Semi-Annually' || billingcycleadd === 'Annually' || billingcycleadd === 'Biennially' || billingcycleadd === 'Triennially') {
                    //console.log('entra a cambiar valor')
                    billingcycleadd = 'recurring'
                }

                if (priceadd === -1 || priceadd === 0) { //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    minAddons.map((add) => {
                        if (add.id === addon.id) {
                            priceadd = add.price;
                            periodeng = add.periodeng;
                        }
                    });
                }

                let nameadd = addon.name
                let idadd = addon.id
                let addondetail = {
                    id: idadd,
                    name: nameadd,
                    price: priceadd,
                    billingcycle: billingcycleadd,
                    periodeng: periodeng
                }

                listaddons.push(addondetail);

            }

        });

        //console.log('lista addons check', listaddons);

        return listaddons;
    }

    useEffect(() => {

        if (idProduct !== '') {

            (async function () {
                try {
                    const prod = await getProduct(idProduct);
                    setProduct(prod);

                    const cycleprod = getCycleProduct(prod);
                    setCycleProduct(cycleprod);

                    //console.log('cycleprod', cycleprod.length, cycleprod);

                    const addons = await getAddons(idProduct);
                    setAddons(addons);

                    //console.log('addons', addons.length, addons);

                    if (addons.length === 0 && cycleprod.length === 1) {

                        const addons = { addons: [] };
                        const orderdata = {
                            idproduct: prod.id,
                            priceproduct: cycleprod[0].price,
                            billingcycle: cycleprod[0].periodeng,
                            subtotaladd: 0.00,
                            total: cycleprod[0].price,
                            nameproduct: prod.name,
                            domain: dominio,
                            eventdomain: eventDomain
                        }
                        const orderdatafull = { ...orderdata, ...addons }
                        navigate('/cart/product/checkout/', { state: { data: orderdatafull } })

                    } else {
                        const minaddons = await getMinAddons(addons);
                        setMinAddons(minaddons);

                        const priceCycleIni = getValIniProd(cycleprod, cycleProdCurrent);
                        //handleSelectCycle(priceCycleIni.price, priceCycleIni.billingcycle);

                        /* precios registro transferencia renovacion dominio* */
                        if (eventDomain === 'domainregister' || eventDomain === 'domaintransfer') {
                            const pricingTld = await getPriceTld(tld, eventDomain, currency);
                            setPricingTld(pricingTld);

                            const priceRenewTld = await getPriceTld(tld, 'domainrenew', currency);
                            setPricingRenewTld(priceRenewTld);

                            const typeFreeDomain = getIsFreeDomain(tld, priceCycleIni.billingcycle, prod);
                            if (typeFreeDomain.isFree) {
                                setPriceDomain(0.00);
                                setPricesIni(priceCycleIni.price, priceCycleIni.billingcycle, 0.00);
                            } else {
                                //get price según extension
                                setPriceDomain(pricingTld.annually); //el precio registro y transferencia solo anual para pedido
                                setPricesIni(priceCycleIni.price, priceCycleIni.billingcycle, pricingTld.annually);
                            }
                            if (typeFreeDomain.type === 'on') {
                                setPriceRenewDomain(0.00)
                            } else { //es once o void
                                //const type = 'domainrenew';
                                //const pricesRenewTld = await getPriceTld(tld, type, currency);
                                //console.log('value type free domain', typeFreeDomain);
                                //console.log('pricesRenewTld', priceRenewTld.annually)
                                setPriceRenewDomain(priceRenewTld.annually);
                            }

                        } else {
                            setPriceDomain(0.00);
                            setPricesIni(priceCycleIni.price, priceCycleIni.billingcycle, 0.00);
                        }
                    }

                } catch (e) {

                }
            })()

        } else {
            navigate('/store/')
        }



    }, [])

    if (idProduct === '') {
        return (
            <SpinnerLoad />
        )
    }

    if (idProduct !== '' && cycleProduct.length === 1 && addons.length === 0) {
        return (
            <SpinnerLoad />
        )
    } else {
        return (
            <>
                <Helmet>
                    <title>Revisar Pedido | Neothek</title>
                </Helmet>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center'
                    }}
                >

                    <Container maxWidth="md">
                        <Grid item xs={12} md={12}>
                        <StepperCart step={1} />
                        </Grid>
                    </Container>

                    <Container maxWidth="md" variant='cont-nk'>

                        <Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box mt={2} mb={3} >
                                        <Typography variant='h4'>Ciclo de Facturación</Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                {/*<Grid item xs={12} sm={6}>
                                        <Typography variant='subtitle1'><strong>{product.name}</strong></Typography>
                                </Grid>*/}

                                <Grid container alignItems="center" justifyContent="center" spacing={6} mt={0}>
                                    {/*  {cycleProduct.map(({ period, price, periodeng }) => (
                                        <Grid key={periodeng} item sx={{ textAlign: 'center' }}>
                                            <CycleComponent period={period} price={price} periodeng={periodeng} cycle={handleSelectCycle} />
                                        </Grid>
                                    ))}   */}

                                   
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="billingcycle" name="billingcycle" defaultValue={billingcycle}>
                                            {cycleProduct.map(({ period, price, periodeng }) => (
                                                <FormControlLabel
                                                key={periodeng}
                                                value={periodeng}
                                                control={<CycleRadio periodeng={periodeng} price={price} period={period} onClick={handleSelectCycle}/>}
                                                label={<><Typography variant='body2' sx={{textAlign:'center'}}><strong>US$ {price}</strong></Typography> <Typography variant='body2' color="textSecondary" sx={{textAlign:'center'}}>{period}</Typography></>}
                                                labelPlacement="bottom"
                                                />
                                            ))}
                                        
                                        </RadioGroup>
                                    </FormControl>
                                    

                                </Grid>

                                <Grid item xs={12}>
                                    <Box mt={1} mb={3} >
                                        <Typography variant='h4'>Complementos Disponibles</Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>

                                    <Grid container spacing={2}>
                                        {minAddons.map(({ id, name, description, price, period }) => (
                                            <Grid key={id} item xs={12} sm={6}>
                                                <Box sx={{textAlign: 'center', backgroundColor: '#F7FAFC' }}>
                                                    <Box sx={{ paddingTop: '3px' }}>
                                                        <Typography variant='body2' color='primary'><strong>{name}</strong></Typography>
                                                    </Box>

                                                    <Box>
                                                        <Grid container spacing={2} sx={{ py: 1 }}>
                                                            <Grid item xs={2} sm={2}>
                                                                <Checkbox
                                                                    icon={<CheckBoxOutline />}
                                                                    checkedIcon={<CheckBoxIcon />}
                                                                    value={id}
                                                                    onChange={handleCheckAddon}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={10} sm={10} sx={{ textAlign: 'justify', paddingRight: '3%' }}>
                                                                <Typography variant='body2' color="#697386" sx={{fontSize: '1.35rem'}}>{description}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} sx={{ textAlign: 'right', paddingRight: '3%' }}>
                                                                <Typography variant='body2' color="#697386" sx={{fontWeight: '600'}}>${price}USD <span>{period}</span></Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </Grid>

                                {/* sumario pedido */}

                                <Grid item xs={12}>
                                    <Box mt={5} mb={2} >
                                        <Typography variant='h4'>Sumario de Pedido</Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>

                                    <Grid container spacing={2} sx={{ mb: 2 }}>
                                        <Grid item sm={8} xs={9} style={{paddingTop: '8px'}}>
                                            <Typography color="colornk.grey2" sx={{fontSize: '1.36rem'}}>{product.name} - {dominio}</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={3} style={{paddingTop: '8px'}}>
                                            <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}>${priceProdCurrent} USD</Typography>
                                        </Grid>

                                        {eventDomain !== 'domainown' &&
                                            <>
                                                <Grid item sm={8} xs={9}>
                                                    {eventDomain === 'domainregister' ?
                                                        <Typography color="colornk.grey2" sx={{fontSize: '1.36rem'}}>Registro de Dominio - {dominio} (1 año)</Typography>
                                                        :
                                                        <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}>Transferencia de Dominio - {dominio} (1 año)</Typography>
                                                    }

                                                </Grid>
                                                <Grid item sm={4} xs={3} style={{paddingTop: '13px'}}>
                                                    <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}>${priceDomain} USD</Typography>
                                                </Grid>
                                                <Grid item sm={7} style={{ paddingTop: 0 }}>
                                                    <Typography color="colornk.grey2" style={{ fontSize: '1.2rem', color: '#b1b4b5' }}>Renovación ${priceRenewDomain} /año</Typography>
                                                </Grid>
                                            </>
                                        }


                                        <Grid item sm={8} xs={9} style={{paddingTop: '13px'}}>
                                            <Typography color="colornk.grey2" sx={{fontSize: '1.36rem'}} >Complementos</Typography>
                                        </Grid>
                                        <Grid item sm={4} xs={3} style={{paddingTop: '13px'}}>
                                            <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}} >${subTotalAdd} USD</Typography>
                                        </Grid>
                                        
                                       
                                        <Grid container spacing={2} sx={{paddingLeft: 2, mt: 2 }}>

                                                <Grid item xs={12} sx={{borderTop: '1px solid #e3e8ee'}} style={{paddingTop: '0px'}}></Grid>
                                            
                                                <Grid item sm={8} xs={8}>
                                                    <Typography color="colornk.grey2" sx={{fontSize: '1.36rem'}}><strong>Subtotal</strong></Typography>
                                                </Grid>
                                                <Grid item sm={4} xs={4}>
                                                    <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}><strong>${subtotal} USD</strong></Typography>
                                                </Grid>
                                            
                                            

                                            { (coupon && discount !== 0.00) &&
                                                <>
                                                    <Grid item sm={8} xs={8} style={{paddingTop: '13px'}}>
                                                        <Typography color="colornk.grey2"  sx={{fontSize: '1.36rem'}}>Cupón: {coupon.code}</Typography>
                                                    </Grid>
                                                    <Grid item sm={4} xs={4} style={{paddingTop: '13px'}}>
                                                        <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}>$-{discount}USD</Typography>
                                                    </Grid>
                                                </>
                                            }

                                            <Grid item sm={8} xs={8} style={{paddingTop: '13px'}}>
                                                <Typography color="colornk.grey2" sx={{fontSize: '1.36rem'}}><strong>Total</strong></Typography>
                                            </Grid>
                                            <Grid item sm={4} xs={4} style={{paddingTop: '13px'}}>
                                                <Typography color="colornk.grey2" sx={{fontSize: '1.36rem', textAlign: 'right'}}><strong>${total} USD</strong></Typography>
                                            </Grid>
                                            
                                        </Grid>
                                    

                                    </Grid>
                                    <Divider />
                                </Grid>

                                {(coupon === null && discount === 0.00) ? 
                                    <Grid item xs={12}>
                                    <Formik initialValues={couponValue} validationSchema={couponValidation} onSubmit={onCouponSubmit}>
                                        {(props) => (

                                            <Form>
                                                <Grid container spacing={2} sx={{ mb: 2 }} dir="rtl">
                                                    <Grid item sm={3} xs={6}>
                                                        <Button type='submit' variant='outlined' fullWidth>
                                                            Aplicar Cupón
                                                        </Button>
                                                    </Grid>
                                                    <Grid item sm={6} xs={6}>

                                                        <Field as={TextField}
                                                            variant="outlined"
                                                            name='coupon'
                                                            label='Código de Cupón'
                                                            fullWidth
                                                            size='small'
                                                            placeholder="Introduzca un código promocional, si dispone de uno"
                                                            dir="ltr"
                                                            error={props.errors.coupon && props.touched.coupon}
                                                            helperText={<ErrorMessage name='coupon' />} required />

                                                        {validCoupon === false && <Typography variant='body2' color='secondary'>Cupón no valido</Typography>}
                                                    </Grid>

                                                </Grid>
                                            </Form>

                                        )}
                                    </Formik>
                                </Grid>

                                :

                                <Grid item xs={12}>
                                    <Grid container dir='rtl'>
                                        <Grid item sm={3}>
                                            <Button variant='outlined' fullWidth onClick={handleDeleteCoupon}>
                                                Eliminar Cupón
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                    
                                
                                }


                                <Grid container justifyContent="flex-end" alignItems="center" >
                                    <Box sx={{ py: 3 }}>
                                        <Button variant='contained' color='primary' onClick={passRegister} size='large' endIcon={<NextIcon />}>
                                            Continuar
                                        </Button>
                                    </Box>

                                </Grid>

                            </Grid>

                        </Box>

                    </Container>

                </Box>
            </>
        )
    }

}

export default ReviewOrder