import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function setPaydoutPayPal(details){
    //console.log('data enviada a paaydout BD', details)
    
    const res = await axios.post(`${apiserv}/gateways/paypal`, details)
        .then(function (response) {
            //console.log(response)
            return response.data;
          })
        .catch(function (error) {
            console.log('da error', error);
          });

    //const res = 'entro setPayout'

    return res
}