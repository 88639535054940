import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { AppBar, Toolbar, Menu, MenuItem, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  [theme.breakpoints.down('sm')]: {
    contMainNav: {
      marginTop: '14%'
    }
  },
  [theme.breakpoints.up('sm')]: {
    contMainNav: {
      marginTop: '8%'
    }
  },
  [theme.breakpoints.up('md')]: {
    contMainNav: {
      marginTop: '5.8%'
    }
  },
  [theme.breakpoints.up('lg')]: {
    contMainNav: {
      marginTop: '4.5%'
    }
  },
  [theme.breakpoints.up('xl')]: {
    contMainNav: {
      marginTop: '3.3%'
    }
  }
  
}));

const MainNavbar = (props) => {

  const classes = useStyles()
  const navigate = useNavigate()

  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () =>{
    navigate('/login/');
    setAnchorEl(null); //para cerrar item
  }
  const handleRegister = () =>{
    navigate('/register/');
    setAnchorEl(null); //para cerrar item
  }
  


  return(
    <>
  {/*<AppBar
  {...props} style={{marginTop: '4%'}}
  >
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
    </Toolbar>
  </AppBar>*/}

{/* <AppBar className={classes.contMainNav} sx={{backgroundColor: '#fbfbfb', color: '#468def', boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 3px 4px -2px rgb(255 255 255 / 25%)'}}> */}
<AppBar className={classes.contMainNav} sx={{backgroundColor: '#fbfbfb', color: '#468def', boxShadow: 'none'}}>
<Toolbar>
  <Typography variant="h6" className={classes.title}> 
    Portal Neothek
  </Typography>
  {/*<Button startIcon={<HomeIcon />}>
    Home
  </Button>*/}
  
  
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="primary"
      >
        <AccountCircle style={{fontSize: '1.5em'}} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        //open={isOpen}
        //onClose={() => setIsOpen(false)}
        
      >
        <MenuItem onClick={handleLogin} className={classes.textItemMenu}><Typography variant='h6'>Ingresar</Typography></MenuItem>
        <MenuItem onClick={handleRegister}><Typography variant='h6'>Registrarse</Typography></MenuItem>
      </Menu>
    </div>
  
</Toolbar>
</AppBar>
  </>
  
  )

}
  
  
  
export default MainNavbar;