import React, { useRef } from 'react';
import Radio from '@material-ui/core/Radio';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { IconButton } from '@material-ui/core'

const Billingcycle = () => {
  return (
    <>
      <IconButton aria-label="date-range" size="medium" sx={{color: 'colornk.grey1'}}>
        <DateRangeIcon sx={{ fontSize: '1.7em' }} />
      </IconButton>
    </>
  )
}

const BillingcycleSelected = () => {
  return (
    <>
      <IconButton aria-label="date-range" size="medium" variant='btnSelected-nk' sx={{backgroundColor: '#635bff', color: '#fff'}}>
        <DateRangeIcon sx={{ fontSize: '1.7em' }} />
      </IconButton>
    </>
  )
}

export default function CycleRadio(props) {
  const calCycle = useRef();

  const handleAtt = () => {
    const price = calCycle.current.attributes.price.value;
    const cycle = calCycle.current.attributes.periodeng.value

    props.onClick(price, cycle)
  }


  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BillingcycleSelected />}
      icon={<Billingcycle />}
      {...props}
      ref={calCycle}
      onClick={handleAtt}
    />
  );
}