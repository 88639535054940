import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import setPaydoutStripe from '../../services/PaymentStripe';
//const urlParams = new URLSearchParams(window.location.search);
//const sessionId = urlParams.get('session_id');

function ReturnStripe(props) {
    const navigate = useNavigate();
    const [dataStripe, setDataStripe] = useState('');
    const {session_id, user_id, invoice_id} = useParams();
    const sessionId = session_id;

    const datainvoice = {
        userid: user_id,
        currency: 0,
        gateway: 'stripe',
        description: 'Invoice Payment',
        invoiceid: invoice_id
      }


    useEffect(() => {
        if (sessionId) {
            fetch('/gateways/stripe/checkout-session?sessionId=' + sessionId)
              .then(function (result) {
                  console.log('result stripe', result);
                return result.json();
              })
              .then(async function (session) {
                const sessionJSON = JSON.stringify(session, null, 2);
                console.log('session stripe', session);
                setDataStripe(sessionJSON);

                const status =  await setPaydoutStripe(session, datainvoice);
                console.log('pago stripe status', status);
                navigate('/clientarea/success-payment/');

              })
              .catch(function (err) {
                console.log('Error when fetching Checkout session', err);
              });
        }
    }, [])

    return (
        <div>
            {dataStripe ? (
                <Container>
                <Typography variant='body2' style={{ textAlign: 'center' }}>Loading Payment...</Typography>
                   {/* <div>{dataStripe}</div>*/}
                </Container>
            ) : (
                <Container>
                    
                </Container>
            )}
        </div>
    );
}

export default ReturnStripe;