import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#635bff'
      //main: '#5469d4'
      //main: '#6C63FF'
      //main: '#2196f3'
    },
    secondary: {
      main: '#8c9eff'
    },
    success: {
      main: '#4caf50',
      light: '#5dd361',
      dark:'#4b9b4f',
      contrastText: '#fff'
    },
    text: {
      primary: '#596275',
      //primary: '#172b4d',
      //secondary: '#6b778c'
    },
    gray: {
      cust1Nk: '#434c5e',
    },
    colornk: {
      grey1: '#8792a2',
    }

  },
  shadows,
  typography,

  components:{
    MuiContainer: {
      variants: [{
        props: {variant: 'cont-nk'},
        style: {
          borderRadius: '8px',
          boxShadow: '0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)'
        }
      }]
    },

    /*MuiButtonBase: {
      variants: [{
        props: {variant: 'btnSelected-nk'},
        style: {
          backgroundColor: '#e1e1fd'
        }
      }]
    }, */

    MuiIconButton: {
      variants: [{
        props: { variant: 'calButton' },
        style: {
            backgroundColor: '#ccf7f5',
            //border: `2px dashed grey${blue[500]}`,
        },
      }, 
      ]
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#adb5c3',
        },
      },
    },

    MuiTableCell : {
      styleOverrides:{
        root: {
          borderBottom: '1px solid #f3f3f5'
        }
      }
    },

    MuiTableContainer:{
      variants: [{
        props: {variant: 'tbLight-nk'},
        style: {
          boxShadow: 'none'
        }
      }]
    },

    MuiTableRow: {
      variants: [{
        props: {variant: 'rowtbLight-nk'},
        style: {
          fontSize: '1.33rem'
        },
      }]
    },

    MuiTableCell: {
      variants: [{
        props: {variant: 'celltbLight-nk'},
        style: {
          fontSize: '1.33rem'
        }
      }]
    },

    MuiTypography: {
      variants: [{
          props: {variant: 'hightlightText-nk'},
          style: {
            fontSize: '1.3rem',
            fontWeight: 600
          }
        },
        {
          props: {variant: 'simpleText-nk'},
          style: {
            fontSize: '1.3rem',
          }
        }
      
      
      ]
    },

    MuiTab: {
      variants: [{
        props: {variant: 'btnTab-nk'},
        style: {
          fontSize: '1.15rem',
          fontWeight: 600,
          minWidth: '100px !important',
          '&.css-9c18rc-MuiButtonBase-root-MuiTab-root':{
            minWidth: '100px'
          }
          /*'&.Mui-selected': {
            color: '#434c5e'
          }*/
        }
      }]
    }

    

    /*MuiTableRow: {  //ejemplo hover rowTable
      styleOverrides: {
        hover:
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
      }
    }, */

  }


});

export default theme;