import React from 'react';
import Alert from '@material-ui/core/Alert';
import { Box } from '@material-ui/core'
import DominioDisponible from './DominioDisponible';
import DominioNoDisponible from './DominioNoDisponible';

function VerificarDominio(props) {
  const status = props.domainstatus;
  const product = props.product;
  const billingcycle = props.billingcycle;
  const tld = props.tld;
  const eventDomain = props.eventDomain;

  if(eventDomain === 'domainregister'){
    if (status === 'available') {
      return <DominioDisponible dominio={props.inputdomain} product={product} billingcycle={billingcycle} tld={tld} eventDomain={eventDomain}/>;
    }
    if (status === 'regthroughus' || status === 'regthroughothers' || status === 'unknown') {
      return <DominioNoDisponible dominio={props.inputdomain} eventDomain={eventDomain}/>;
    }
  }
  if(eventDomain === 'domaintransfer'){
    if(status === 'regthroughothers'){
      return <DominioDisponible dominio={props.inputdomain} product={product} billingcycle={billingcycle} tld={tld} eventDomain={eventDomain}/>;
    }
    if(status === 'available' || status === 'regthroughus' || status === 'unknown'){
      return <DominioNoDisponible dominio={props.inputdomain} eventDomain={eventDomain}/>;
    }
  }
  
  return (
    <Box mt={3}>
      <Alert severity="info">Ingresa el dominio y verificaremos su disponibilidad</Alert>
    </Box>
  )


}

export default VerificarDominio;