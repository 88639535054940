import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function getServicesClient() {

    const cid = JSON.parse(localStorage.getItem('_cli_storage_')).cid;
    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/orders/${cid}`;

    const client = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data services', res.data)
            return { ...res.data, ...{ error: false } };
        })
        .catch((error) => {
            //console.log(error.config);
            return { error: true }

        });

    return client;

}