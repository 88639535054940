import { Grid, Typography, Container, Box, Button, TextField, Divider, Checkbox, FormControl, FormControlLabel, MenuItem, List, ListItem, ListItemText, ListItemSecondaryAction, Radio, RadioGroup, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import StepperCart from '../components/FormsUI/StepperCart'
import { useLocation } from "react-router-dom"
import { Form, Formik, Field } from 'formik'
//import BillingCycle from './BillingCycle';
import TextFieldForm from '../components/FormsUI/TextFieldForm'
import * as Yup from 'yup'
import CountrySelector from '../components/FormsUI/CountrySelect'
import SelectPhone from '../components/FormsUI/PhoneInput'
import { useNavigate } from 'react-router-dom'


const RegisterOrder = ( ) => {

    const navigate = useNavigate();

    const location = useLocation();
    const publicIp = require('public-ip');
    //console.log("location state dominio " + location.state.dominio);
    //console.log("location state id product " + location.state.product)

    //TABS
    const [valueTab, setValueTab] = useState('1');  
    const handleChangeTab = (event, newValue) => {
      setValueTab(newValue);
    }

    const [checked, setChecked] = useState(true);
    const [loading, setLoading] = useState(true)

    //state product
    //const [idproduct, setidproduct] = useState(location.state.product);
    const [idproduct, setidproduct] = useState(1);
    const [product, setproduct] = useState({
        id:'',
        name:'',
        monthly:'',
        quarterly:'',
        semiannually:'',
        annually:'',
        biennially:'',
        triennially:''
    });
    const [mincycle, setmincycle] = useState({period: "", price: 0, periodeng: ""});
    const [cycleproduct, setcycleproduct] = useState([{period: "Cargando...", price: "", periodeng: ""}]);
    const [cycleselect, setcycleselect] = useState("annually");
    const [priceprod, setpriceprod] = useState(0.0);

    //state addons
    const [addonsmin, setaddonsmin] = useState([{}]);
    const [addons, setaddons] = useState([{}]);

    //precios cart
    const [subtotaladd, setsubtotaladd] = useState(0.0);
    const [total, settotal] = useState(0.00);

    //payment methods
    const [paymentgw, setpaymentgw] = useState([{}]); 
    const [payment, setpayment] = useState('tco');

    const [paymentbo, setpaymentbo] = useState([{id: 235, gateway: "banktransfer", value: "BOL (Pago en Bancos)"}]);
    const [paymentus, setpaymentus] = useState([{id: 4, gateway: "tco", value: "Tarjeta de crédito"}]);
    const [paymentall, setpaymentall] = useState([
        {id: 4, gateway: "tco", value: "Tarjeta de crédito"},
        {id: 12, gateway: "mailin", value: "Western Union"},
        {id: 28, gateway: "stripe", value: "Tarjeta de Crédito / Débito"},
        {id: 102, gateway: "paypal", value: "PayPal"},
        {id: 230, gateway: "payu", value: "COL, MEX, PER  ( Pago Local )"}
    ]);



    //code country and phone
    const [codcountry, setcodcountry] = useState('us');
    const [phone, setphone] = useState('');

    function putCodCountry(value){
        const cc = value.toLowerCase()
        setcodcountry(cc)
        //console.log('code country :', codcountry);

        //cambiar metodo pago por pais
        if(cc === 'bo'){
            //const { setFieldValue } = useFormikContext();
            setpaymentgw(paymentbo)
            //setpayment('banktransfer')
         
            
        }else {if(cc === 'us'){
            setpaymentgw(paymentus)
            //setpayment('tco')
        }else{
            setpaymentgw(paymentall)
            //setpayment('tco')
        }
    }

    }


    function putPhone(value){
        setphone(value)
    }


    /*const handleChange = (ev) => {
        setChecked(event.target.checked);
    };*/
    const handleCheck = (ev) => {

        let priceadd=0;
        let idaddcheck = ev.target.value;
        let ischeck = ev.target.checked;
        let newsubtotaladd = 0;

        priceadd = getPriceAddonCheck(idaddcheck, ischeck);

        if(ev.target.checked){
            newsubtotaladd = priceadd + subtotaladd;

            //console.log(ev.target.checked);
            //console.log('precio addon: ', priceadd);
        }else{
            newsubtotaladd = subtotaladd - priceadd;
        }

        setsubtotaladd(newsubtotaladd);

        let newtotal = (priceprod + newsubtotaladd).toFixed(2);
        settotal(newtotal);
        
    }

    const getPriceAddonCheck = (idaddonp, state) => {

        let priceadd= 0;
        let ischecknew=state;
        let idaddon = parseInt(idaddonp);
        addons.map( (addon) => {
            if(addon.id === idaddon){
                //console.log('cycleselect value: ', cycleselect);
                priceadd = addon[cycleselect];
                addon.ischeck = ischecknew;

                if(priceadd === -1 || priceadd === 0){ //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    addonsmin.map( (add) => {
                        if(add.id === idaddon){
                            priceadd = add.price;
                        }
                    });
                }
            }
        });

        //console.log('addonsluego check is check ', addons);

        return priceadd;
    }

    const getPriceAllAddonCheck = (period) => {

        let priceadd= 0;
        let newsubtadd = 0;
        addons.map( (addon) => {
            if( addon.ischeck == true){
                priceadd = addon[period];

                if(priceadd === -1 || priceadd === 0){ //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    addonsmin.map( (add) => {
                        if(add.id === addon.id){
                            priceadd = add.price;
                        }
                    });
                }

                newsubtadd = newsubtadd + priceadd;

            } 
            
        });

        //console.log('new subtadd check all', newsubtadd);

        return newsubtadd;
    }

    const handleCycle = (ev) => {
        //console.log("valor de cambio select periodeng", ev.target.value);
        let newtotal = 0;
        const periodsel = ev.target.value;
        let newprice = product[periodsel];


        let newsubtotaladd = getPriceAllAddonCheck(periodsel);

        newtotal = (newprice + newsubtotaladd).toFixed(2);
        //newtotal = newprice + subtotaladd;

        setpriceprod(newprice)
        setcycleselect(periodsel);
        setsubtotaladd(newsubtotaladd);
        settotal(newtotal);
    }

    //get type addon mas precios minimos
    function gettypeaddon(addons){

        let addonsminarr = [];

        addons.map( (body) => {
            let mincyclenew = {};

        //si billyngcyle no es onetime o free
        if(body.billingcycle === "recurring" ){

        if(body.monthly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Mensual'; mincyclenew.description=body.description; mincyclenew.price=body.monthly; mincyclenew.periodeng='monthly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
        else {
            if(body.quarterly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Trimestral'; mincyclenew.description=body.description; mincyclenew.price=body.quarterly; mincyclenew.periodeng='quarterly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
            else{
                if(body.semiannually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Semi-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.semiannually; mincyclenew.periodeng='semiannually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                    if(body.annually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Anual'; mincyclenew.description=body.description; mincyclenew.price=body.annually; mincyclenew.periodeng='annually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                        if(body.biennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Bi-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.biennially; mincyclenew.periodeng='biennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                            if(body.triennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Tri-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.triennially; mincyclenew.periodeng='triennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)};
                        }   
                    }   
                }  
            }   
        }


    } //si es onetime o free
       else{
            //si es onetime
            if(body.billingcycle === "onetime"){
                    if(body.monthly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.monthly; mincyclenew.periodeng='monthly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
                    else {
                        if(body.quarterly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.quarterly; mincyclenew.periodeng='quarterly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
                        else{
                            if(body.semiannually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.semiannually; mincyclenew.periodeng='semiannually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                if(body.annually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.annually; mincyclenew.periodeng='annually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                    if(body.biennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.biennially; mincyclenew.periodeng='biennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                        if(body.triennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Una vez'; mincyclenew.description=body.description; mincyclenew.price=body.triennially; mincyclenew.periodeng='triennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)};
                                    }   
                                }   
                            }  
                        }   
                    } //fin

            }else{
                //si es free
                if(body.billingcycle === "free"){

                    mincyclenew.name=body.name; mincyclenew.period='Gratis!'; mincyclenew.description=body.description; mincyclenew.price=0; mincyclenew.periodeng='monthly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)

                }//si no es onetime - free- recurring, lo mismo q recurring temporal
                    else{
                        //ini
                        if(body.monthly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Mensual'; mincyclenew.description=body.description; mincyclenew.price=body.monthly; mincyclenew.periodeng='monthly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
                        else {
                            if(body.quarterly !== -1) {mincyclenew.name=body.name; mincyclenew.period='Trimestral'; mincyclenew.description=body.description; mincyclenew.price=body.quarterly; mincyclenew.periodeng='quarterly'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} 
                            else{
                                if(body.semiannually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Semi-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.semiannually; mincyclenew.periodeng='semiannually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                    if(body.annually !== -1) {mincyclenew.name=body.name; mincyclenew.period='Anual'; mincyclenew.description=body.description; mincyclenew.price=body.annually; mincyclenew.periodeng='annually'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                        if(body.biennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Bi-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.biennially; mincyclenew.periodeng='biennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)} else{
                                            if(body.triennially !== -1) {mincyclenew.name=body.name; mincyclenew.period='Tri-Anual'; mincyclenew.description=body.description; mincyclenew.price=body.triennially; mincyclenew.periodeng='triennially'; mincyclenew.id=body.id; addonsminarr.push(mincyclenew)};
                                        }   
                                    }   
                                }  
                            }   
                        }//end
                        
                }
            }


    }

        

    

    });

        //poner minimos para mostrar en list, add description, periodeng, recurring
        //console.log('arrayaddons', addonsminarr);

        setaddonsmin(addonsminarr);

    }
    
    function getmincycle(body){
        let mincyclenew = {};

        if(body.monthly !== -1) {mincyclenew.period='Mensual'; mincyclenew.price=body.monthly; mincyclenew.periodeng='monthly'} 
        else {
            if(body.quarterly !== -1) {mincyclenew.period='Trimestral'; mincyclenew.price=body.quarterly; mincyclenew.periodeng='quarterly'} 
            else{
                if(body.semiannually !== -1) {mincyclenew.period='Semi-Anual'; mincyclenew.price=body.semiannually; mincyclenew.periodeng='semiannually'} else{
                    if(body.annually !== -1) {mincyclenew.period='Anual'; mincyclenew.price=body.annually; mincyclenew.periodeng='annually'} else{
                        if(body.biennially !== -1) {mincyclenew.period='Bi-Anual'; mincyclenew.price=body.biennially; mincyclenew.periodeng='biennially'} else{
                            if(body.triennially !== -1) {mincyclenew.period='Tri-Anual'; mincyclenew.price=body.triennially; mincyclenew.periodeng='triennially'};
                        }   
                    }   
                }  
            }   
        }
        

        setmincycle(mincyclenew);
        //poner minimo al precio del prod
        setpriceprod(mincyclenew.price);
        setcycleselect(mincyclenew.periodeng);
        settotal(mincyclenew.price.toFixed(2));

    }

    function getcycledisp(body){

        let arrayperiod = [];
        
        if(body.monthly !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Mensual';
            cycleprod.price = body.monthly;
            cycleprod.periodeng = 'monthly';
            arrayperiod.push(cycleprod);
        };
        if(body.quarterly !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Trimestral';
            cycleprod.price = body.quarterly;
            cycleprod.periodeng = 'quarterly';
            arrayperiod.push(cycleprod);
        };
        if(body.semiannually !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Semi-Anual';
            cycleprod.price = body.semiannually;
            cycleprod.periodeng = 'semiannually';
            arrayperiod.push(cycleprod);
        };
        if(body.annually !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Anual';
            cycleprod.price = body.annually;
            cycleprod.periodeng = 'annually';
            arrayperiod.push(cycleprod);
        };
        if(body.biennially !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Bi-Anual';
            cycleprod.price = body.biennially;
            cycleprod.periodeng = 'biennially';
            arrayperiod.push(cycleprod);
        };
        if(body.triennially !== -1) {
            let cycleprod = {};
            cycleprod.period = 'Tri-Anual';
            cycleprod.price = body.triennially;
            cycleprod.periodeng = 'triennially';
            arrayperiod.push(cycleprod);
        };

        //console.log('cycleproduct array ', arrayperiod);

        setcycleproduct(arrayperiod);

    }

    //payment method
    const handlePayment = (ev) => {
        setpayment(ev.target.value);
        //console.log('payment select ', payment);
    }

    //

    //get values product por id
    useEffect(() => {

        let unmounted = false

        let apiprod = "api/priceproduct/"+idproduct;
        let apiaddon = "api/addonsproductprice/"+idproduct;
        let apipayment = "api/paymentgateways/"

        async function getValuesProduct() {
          const response = await fetch(apiprod)
          const body = await response.json()
          //console.log(body);

          if (!unmounted){
          getcycledisp(body);
          getmincycle(body);

          setproduct({
              id: body.id,
              name: body.name,
              monthly: body.monthly,
              quarterly: body.quarterly,
              semiannually: body.semiannually,
              annually: body.annually,
              biennially: body.biennially,
              triennially: body.triennially
          });

          setLoading(false); 

        }

        }


        async function getAddons() {
            const res = await fetch(apiaddon)
            const apiaddons = await res.json()
            
            //console.log(apiaddons);
            
            //ini
            const addonsarray = [];

            apiaddons.map( (apiaddon) => {
                let addn = {ischeck: false};
                addn = {...apiaddon, ...addn};

                addonsarray.push(addn);

                //console.log('addonsarray', addonsarray);
            });
            

            //end
            setaddons(addonsarray);

            //setaddons(apiaddons);

            gettypeaddon(apiaddons);
  
        }

        async function getPaymentGw(){
            const resp = await fetch(apipayment)
            const apipayments = await resp.json()

            //setpaymentgw(apipayments);

            //se puso por default us
            setpaymentgw(paymentus);

            //console.log('payments', apipayments);
        }

        getValuesProduct();
        getAddons();
        getPaymentGw();


        return () =>{
            unmounted = true;
          }
        

        
      }, [])
  
      
    //formik
    const initialValues = {
        periodform: cycleselect,
        billingcycle: cycleselect,
        firstname: '',
        lastname: '',
        email: '',
        country: 'US',
        city:'',
        state:'',
        address1:'',
        address2:'',
        companyname:'',
        postcode:'',
        phonenumber:'',
        password:'',
        password2:'',
        paymentmethod:'tco'
    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('Necesita ingresar Nombre'),
        lastname: Yup.string()
            .required('Necesita ingresar Apellido'),
        email: Yup.string()
            .email('Necesita ingresar E-mail')
            .required('Requerido'),
        city: Yup.string()
            .required('Necesita ingresar Ciudad'),
        state: Yup.string()
            .required('Necesita ingresar Provincia/Estado'),
        address1: Yup.string()
            .required('Necesita ingresar Dirección 1'),
        address2: Yup.string(),
        companyname: Yup.string(),
        postcode: Yup.string()
            .matches(/^[0-9]+$/, 'Por favor ingrese un código postal válido')
            .min(4, 'Se acepta mínimo 4 dígitos')
            .max(5, 'Se acepta máximo 5 dígitos')
            .required('Necesita ingresar un Código Postal'),
        phonenumber: Yup.number()
            .integer()
            .typeError('Por favor ingrese un número telefónico válido')
            .required('Necesita ingresar un número telefónico válido'),
        password: Yup.string()
            .required('Necesita ingresar contraseña')
            .min(8, 'Usa 8 caracteres como mínimo').max(255),
        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'La contraseña escrita no coincide')
        
    })

    const onSubmit = (values, props) => {
        //console.log(values);
    
        handleRoute(values)
    }

    async function handleRoute(val){
        const listaddons = getListAddonsCheck();
        const ipv4 = await publicIp.v4();
        const addons = {
            addons: listaddons
        }

        const orderdata = {
            ip: ipv4,
            idproduct: idproduct,
            priceproduct: priceprod,
            subtotaladd: subtotaladd,
            total: total,
            product: product.name,
            domain: 'patitodelaguna.com'
            //domain: location.state.dominio
        }
        const orderdatafull = {...val, ...orderdata, ...addons}

        navigate('/payment', orderdatafull)
    }

    function getListAddonsCheck(){

        const listaddons = [];
        const period = cycleselect; //con cicle select de producto

        addons.map( (addon) => {
            if( addon.ischeck == true){
                let priceadd = addon[period];

                if(priceadd === -1 || priceadd === 0){ //buscar en los precios min addon, caso 0 para casos addon antiguos como Kaspersky
                    addonsmin.map( (add) => {
                        if(add.id === addon.id){
                            priceadd = add.price;
                        }
                    });
                }

                let nameadd= addon.name
                let idadd = addon.id
                let addondetail = {
                    id: idadd,
                    name: nameadd,
                    price: priceadd
                }

                listaddons.push(addondetail);

            } 
            
        });

        console.log('lista addons check', listaddons);

        return listaddons;
    }


    return(
        <>
        <Helmet>
            <title>Registrar Pedido | Neothek</title>
        </Helmet>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
        
        <Container maxWidth="sm">
                <Grid item xs={12} md={12}>
                    <Box sx={{ mt: 1, mb: 6 }}>
                    <StepperCart step={1} />
                    </Box>
                </Grid>
                

                <Grid item xs={12} align='center'> 
                    <Box mt={5} mb={5}>        
                        <Typography variant='h2' >Registro de Cliente</Typography>
                    </Box>
                    {/*<Typography variant='body1' color="textSecondary" align='center'>Felicidades <strong>location.state.dominio</strong> puede registrarse con tu Hosting! </Typography> */}
                </Grid>

        </Container> 
                
        <Container maxWidth="sm" sx={{border: '1px solid #dadce0', borderRadius:'8px'}}>
                    <Box>
                        <TabContext value={valueTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <Tab label="Crear cuenta" value="1" sx={{fontSize: '11px'}}/>
                            <Tab label="Ya soy cliente" value="2" sx={{fontSize: '11px'}}/>
                            </TabList>
                        </Box>
                        <TabPanel value="1" style={{paddingLeft: 0, paddingRight: 0}}>
                            {/* registro nuevo */}
                            <Box>
                            
                            <Formik 
                                initialValues={initialValues}
                                validationSchema={validationSchema} 
                                onSubmit={onSubmit}
                            >
                            {(props) => (

                                <Form>
                                    <Grid container spacing={2}>

                                    

                                    <Grid item xs={12}>
                                        <Box mt={5} mb={3} > 
                                            <Typography variant='h4'>Información de la Cuenta</Typography>
                                            <Divider />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='firstname' label='Nombre'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='lastname' label='Apellido'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='email' label='E-mail'></TextFieldForm>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        {/*<CountrySelector sendCodeCountry = {putCodCountry}></CountrySelector>*/}
                                        <CountrySelector name='country' label='País' sendCodeCountry = {putCodCountry}></CountrySelector>
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='city' label='Ciudad'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='state' label='Provincia'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='address1' label='Dirección 1'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='address2' label='Dirección 2'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='companyname' label='Compañía (Opcional)'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='postcode' label='Código Postal'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SelectPhone country-calling-code-phonenumber={codcountry} country={codcountry} name='phonenumber' label='Número de Teléfono' sendPhone = {putPhone}></SelectPhone>
                                        {/*<TextFieldForm name='phonenumber' label='Número de Teléfono'></TextFieldForm>*/}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='password' label='Contraseña' type='password'></TextFieldForm>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextFieldForm name='password2' label='Confirmar Contraseña' type='password'></TextFieldForm>
                                    </Grid>

                                    

                                    <Grid item xs={12}>
                                        <Box mt={5} mb={3} > 
                                            <Typography variant='h4'>Información del Producto</Typography>
                                            <Divider />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        {/*<Field as={TextField} variant="outlined" value={product.name} label='Plan' size='small' fullWidth disabled/>*/}
                                        <Typography variant='subtitle1'><strong>{product.name}</strong></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <Field as={TextField} disabled={loading} size="small" variant="outlined" label='Periodo' name='periodform' onChange={e => {props.handleChange(e); handleCycle(e)}} select fullWidth >

                                    {cycleproduct.map(({ period, price, periodeng }) => (
                                            <MenuItem key={price} value={periodeng}>
                                                {period}
                                            </MenuItem>
                                        ))}

                                    </Field>
                                    </Grid>
                                    
                                    {/*<Grid item xs={12}>
                                        <BillingCycle name='billingcycle' value={mincycle.period} ></BillingCycle>
                                    </Grid>*/}

                                    

                                    <Grid item xs={12}>
                                        <Box mt={5} mb={3} > 
                                            <Typography variant='body1' color="textSecondary">Complementos Disponibles</Typography>
                                            <Divider />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>                   
                                    
                                            {addonsmin.map(addon => (
                                                <Grid item xs={12} md={6}>
                                                <List>
                                                <ListItem button key={addon.id} >
                                                <Checkbox
                                                    value={addon.id}
                                                    color="primary"
                                                    onChange = {handleCheck}
                                                />
                                                <ListItemText primary={addon.name} secondary={addon.description} />
                                                <ListItemSecondaryAction>
                                                <ListItemText primary={'$ '+(addon.price)} secondary={addon.period} align='right' />
                                                </ListItemSecondaryAction>
                                                </ListItem>
                                                
                                                </List>
                                                </Grid>

                                            ))}
                                        
                                        </Grid>

                                    </Grid>


                                    <Grid item xs={12}>
                                        <Box mt={5} > 
                                            <Typography variant='h4'>Medios de Pago</Typography>
                                            <Divider />

                                            <FormControl component="fieldset">
                                            <RadioGroup aria-label="payment" name="paymentmethod" value={payment} onChange={e => {props.handleChange(e); handlePayment(e)}}>
                                                {paymentgw.map(({ id, gateway, value }) => (
                                                    <FormControlLabel key={id} value={gateway} control={<Radio color="primary" />} label={value} />
                                                ))}
                                            </RadioGroup>
                                            </FormControl>

                                        </Box>
                                    </Grid> 


                                    <Grid item xs={12}>

                                    <Box mt={5} mb={3}>
                                        <Typography variant='h4'>Sumario de Pedido</Typography>
                                        <Divider />
                                        <Grid item xs={12} >
                                            <Typography variant='subtitle1'><strong>Precio Prod: </strong> US$ {priceprod} </Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography variant='subtitle1'><strong>Subtotal Addons: </strong> US$ {subtotaladd} </Typography>
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Typography variant='subtitle1'><strong>Total: </strong>US$ {total} </Typography>
                                        </Grid>
                                    </Box>
                                    </Grid>

                                    


                                    <Grid container justify="center" >
                                        <Box mb={10}>
                                            <Button type='submit' variant='contained' color='primary' size='large'>
                                                Completar Pedido
                                            </Button>
                                        </Box>
                                            
                                    </Grid>


                                    </Grid>
                                </Form>

                            )}      
                            </Formik>

                            </Box>
                            
                            {/* registro nuevo */}
                        </TabPanel>
                        <TabPanel value="2">
                            Login 
                        </TabPanel>
                        </TabContext>
                    </Box>
                

        </Container>
                                        
        </Box>
        </>
    )
}

export default RegisterOrder