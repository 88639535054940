import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import Box from '@material-ui/core/Box'
import StepLabel from '@material-ui/core/StepLabel'


function getSteps() {
  //return ['Buscar Dominio', 'Revisar Orden', 'Registrar y Pagar'];
  return ['Plan', 'Ajustes', 'Revisar y Pagar'];
}

export default function HorizontalLabelPositionBelowStepper( {step}) {
  const [activeStep, setActiveStep] = React.useState(step);
  const steps = getSteps();

  return (
    <>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Stepper activeStep={activeStep}>
            {steps.map((label) => (
            <Step key={label}>
                <StepLabel
                  // icon={<Icon style={{color: 'red'}} />} 
                  color="success"
                >{label}
                </StepLabel>
            </Step>
            ))}
        </Stepper>
      </Box>
        
            
    </>
  )
}
