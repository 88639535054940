import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function setPaydoutPayu(detpay, invoice) {

  const data = {
    datagateway: detpay,
    datainvoice: invoice
  }

  //console.log('data enviada a payu BD', data)

  const res = await axios.post(`${apiserv}/gateways/payu/`, data)
    .then(function (response) {
      //console.log(response)
      return response.data;
    })
    .catch(function (error) {
      //console.log('da error', error);
    });

  return res
}