import { Navigate } from 'react-router-dom'
import MainLayout from './components/MainLayout';
import DashboardLayout from './components/DashboardLayout'
import CartLayout from './components/CartLayout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import RegisterOrder from './pages/RegisterOrder';
import SearchDomain from './pages/SearchDomain';
import Store from './pages/Store';
import ReviewOrder from './pages/ReviewOrder/ReviewOrder';
import Checkout from './pages/Checkout/Checkout';
import Invoice from './pages/Invoice';
import SuccessPayment from './components/payments/SuccessPayment';
import Return2CO from './components/payments/Return2CO';
import ReturnStripe from './components/payments/ReturnStripe';
import ReturnPayu from './components/payments/ReturnPayu';
import Services from './pages/Products/Services';
import Domains from './pages/Domains/Domains';
import Invoices from './pages/Invoices/Invoices';
import Notfound from './pages/NotFound';
import Addons from './pages/Addons'
import ForgetPassword from './pages/ResetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

const routes = (isAuth) => [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Navigate to="/store" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/store',
      element: !isAuth ? <MainLayout /> : <Navigate to="/clientarea/dashboard" />,
      children:[
        { path: '/', element: <Store /> }
      ]
    },
    {
      path: '/404',
      element: isAuth ? <DashboardLayout /> : <MainLayout/>,
      children: [
        { path: '/', element: <Notfound /> },
      ]
    },
    {
      path: '/cart',
      element: isAuth ? <DashboardLayout /> : <MainLayout/>,
      children:[
        { path: 'product/:pid', element: <SearchDomain /> },
        { path: 'product/:pid/billingcycle/:cycle', element: <SearchDomain /> },
        { path: 'product/revorder', element: <ReviewOrder />},
        { path: 'product/regorder', element: <RegisterOrder /> },
        { path: 'product/checkout', element: <Checkout /> },
      ]
    },
    {
      path: '/clientarea',
      element: isAuth ? <DashboardLayout /> : <Navigate to="/login" />,
      children:[
        { path: 'dashboard', element: <Dashboard/> },
        { path: 'invoices/:id', element: <Invoice/> },
        { path: 'return-2co', element: <Return2CO/>},
        { path: 'return-stripe/:session_id/:user_id/:invoice_id', element: <ReturnStripe/> },
        { path: 'response-payu/:user_id/:invoice_id', element: <ReturnPayu/>},
        { path: 'confirmation-payu/:user_id/:invoice_id', element: <ReturnPayu/>},
        { path: 'success-payment', element: <SuccessPayment/> },

        { path: 'products', element: <Services/> },
        { path: 'products/addons/:id', element: <Addons /> },
        { path: 'domains', element: <Domains/> },
        { path: 'invoices', element: <Invoices/> },

      ]
    },
    {
      path: '/',
      //element: <MainLayout />,
      element: !isAuth ? <MainLayout /> : <Navigate to="/clientarea/dashboard" />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'reset', element: <ForgetPassword /> },
        { path: 'reset/password', element: <ResetPassword />}
      ]
    },
  ];
  
export default routes;

/*import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes; */
