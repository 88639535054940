const appConfig = {
        //url_domain: 'http://localhost:3000',
        url_domain: 'https://portal.tudominio.info',
        pk_stripe: process.env.REACT_APP_PK_STRIPE,
        sid_2co: 1310877, //251583180385
        merchantid_payu: 508029,
        apikey_payu: '4Vj8eK4rloUd272L48hsrarnUA',
        url_payu:'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/',
        //url_api: 'http://localhost:4000',
        url_api: 'https://portal.tudominio.info:4000',
        //sitekey_captcha: '6LcPPYAbAAAAANzdHcxtpyxdTK-YIDG21c3yxEhK',
        sitekey_captcha: '6LcX1kIdAAAAAF4q5oR9PrK9I_FrhK6wTd1OUvz3',
}

export default appConfig
