import axios from 'axios';
import appConfig from '../config/main.config';
const apiserv = appConfig.url_api;

export default async function getInvoiceClient(id) {

    const token = JSON.parse(localStorage.getItem('_cli_storage_')).token;
    const api = `${apiserv}/invoices/${id}`;

    const invoice = axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            //console.log('res data', res.data)
            return res.data;
        })
        .catch((error) => {
            //console.log(error.config);

        });

    return invoice;

}