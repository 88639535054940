import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Typography, Divider, Tab, Paper } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import getInvoicesClient from '../../services/Invoices';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import TableInvoices from './TableInvoices';
import SpinnerLoad from '../../components/FormsUI/Spinner';


const Invoices = () => {
    const [fullInvoices, setFullInvoices] = useState(null);
    const [error, setError] = useState(false);

    //to pagination
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    //to filter
    const [status, setStatus] = useState('all');
    const [invoices, setFilteredData] = useState(null);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };
    const handleStatusChange = (event, newValue) => {
        setPage(0);
        setStatus(newValue);
    };

    useEffect(() => {

        const getInvoices = async () => {
            const invoices = await getInvoicesClient();

            if (invoices.length === 0) {
                setError(true);
            } else {
                setFullInvoices(invoices);
                //Filtrando los pagados en caso que no exista, poner all
                const unpaidInvoices = invoices.filter(inv => inv.status === 'Unpaid');
                if (unpaidInvoices.length !== 0) {
                    setFilteredData(unpaidInvoices);
                    setStatus('Unpaid');
                } else {
                    setFilteredData(invoices)
                }
            }
        }

        getInvoices();

    }, []);

    useEffect(() => {
        setFilteredData(status === 'all' ? fullInvoices : fullInvoices.filter(dt => dt.status === status))
    }, [status])

    return (
        <>
            <Helmet>
                <title>Área de Cliente | Neothek</title>
            </Helmet>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >


                {fullInvoices === null || invoices === null ?

                    <>
                        {error ?
                            <Container maxWidth="sm" variant='cont-nk'>

                                <Box sx={{ mb: 2, mt: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        align="center"
                                    >
                                        Lo sentimos,
                                    </Typography>

                                </Box>

                                <Box sx={{ pt: 1, pb: 2 }}>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body1"
                                        align="center"
                                    >
                                        Ha existido una falla o aún no tienes servicios registrados bajo tu cuenta.
                                    </Typography>

                                </Box>

                            </Container>

                            :

                            
                            <SpinnerLoad />
                        }
                    </>

                    :

                    <Container maxWidth="lg" variant='cont-nk'>
                        {/* con tabs */}

                        <Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box mt={3} mb={3} >
                                        <Typography variant='h4'>Mis Pagos</Typography>
                                        <Divider />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>

                                    <TabContext value={status} component={Paper}>
                                        <Box sx={{ borderBottom: 1, borderColor: '#e8f2ff' }}>
                                            <TabList onChange={handleStatusChange} aria-label="tabs estados pagos">
                                                <Tab label="Todos" value="all" variant='btnTab-nk' />
                                                <Tab label="Pagados" value="Paid" variant='btnTab-nk' />
                                                <Tab label="No Pagados" value="Unpaid" variant='btnTab-nk' />
                                                <Tab label="Cancelados" value="Cancelled" variant='btnTab-nk' />
                                                <Tab label="Rembolsados" value="Refunded" variant='btnTab-nk' />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="all">
                                            <TableInvoices invoices={invoices} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableInvoices>
                                        </TabPanel>
                                        <TabPanel value="Paid">
                                            <TableInvoices invoices={invoices} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableInvoices>
                                        </TabPanel>
                                        <TabPanel value="Unpaid">
                                            <TableInvoices invoices={invoices} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableInvoices>
                                        </TabPanel>
                                        <TabPanel value="Cancelled">
                                            <TableInvoices invoices={invoices} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableInvoices>
                                        </TabPanel>
                                        <TabPanel value="Refunded">
                                            <TableInvoices invoices={invoices} page={page} limit={limit} handlePageChange={handlePageChange} handleLimitChange={handleLimitChange}></TableInvoices>
                                        </TabPanel>
                                    </TabContext>

                                </Grid>



                            </Grid>

                        </Box>

                        


                    </Container>
                }



            </Box>
        </>
    )
    /*}*/

}

export default Invoices;