import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExtensionIcon from '@material-ui/icons/Extension';

// ----------------------------------------------------------------------

export default function ServiceMoreMenu(props) {
    const { service } = props;
    const navigate = useNavigate();

    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleButton = () => {
        if (service.addons.length !== 0) {
            navigate(`/clientarea/products/addons/${service.addons[0].hostingid}`, { state: { service: service } });
        }

    }

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{ padding: '5px' }}>
                <MoreVertIcon />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >

                <MenuItem sx={{ color: 'text.secondary' }} onClick={handleButton}>
                    <ListItemText primary="Complementos" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>

                {/* <MenuItem sx={{ color: 'text.secondary' }} onClick = {handleButton}>
          <ListItemIcon>
              <ExtensionIcon color='primary' sx={{width: '0.8em', height: '0.8em'}} />
          </ListItemIcon>
          <ListItemText primary="Complementos" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

            </Menu>
        </>
    );
}
