exports.saveAuth = (res) => {
    //console.log()
    const user = {token: res.token, uid:res.uid, cid:res.cid, language:res.language};
    localStorage.setItem('_cli_storage_', JSON.stringify(user)); 
    localStorage.setItem('_isAuthenticated_', true);
}
exports.logout = () => {
    localStorage.removeItem('_cli_storage_');
    localStorage.removeItem('_isAuthenticated_');
    //localStorage.clear();
}